import React, { useState } from 'react';
import './Footer.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().matches(/^\d{10}$/, 'Mobile must be a 10-digit number').required('Mobile is required'),
    message: Yup.string().required('Message is required'),
});

const Footer = () => {

    const [showPreloader, setShowPreloader] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {

            setShowPreloader(true);

            const response = await fetch('http://localhost:3001/api/footer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            const data = await response.json();

            // Handle the response from the server
            if (data === 'success') {
                document.getElementById('SuccessMessage').innerHTML = "Form Submitted successfully!";
                document.getElementById('SuccessMessage').style.color = "green";
            }

            setTimeout(() => {
                document.getElementById('SuccessMessage').innerHTML = '';
            }, 3000);

            document.getElementById('footer-form').reset();

        } catch (error) {
            console.error('Error:', error);
            document.querySelector(".errormess").innerHTML = 'Form not Submitted!';
            setShowPreloader(false);
        } finally {
            setShowPreloader(false);
            setSubmitting(false);
        }

        console.log('success')

    };

    return (
        <>

            <div style={{ display: showPreloader ? 'flex' : 'none' }} className='preloader-holder'>
                <img src={process.env.PUBLIC_URL + `/images/1490.gif`} alt="Loader" />
            </div>
            <footer className='padding'>
                <div className="container">
                    <h2 className='footer'> Get in touch </h2>

                    <div className="footer-flex">
                        <div className="footer-form">
                            <p>Looking to connect with Caressa Kahn as a brand, community member, product partner? Please fill out the form below and we will get back to you shortly.</p>

                            <Formik

                                initialValues={{
                                    name: '',
                                    email: '',
                                    phone: '',
                                    message: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}>
                                {({ }) => (

                                    <Form className='footer-form-tag' method="POST" id='footer-form'>
                                        <div className='form-field'>
                                            <Field type="text" id="footer-name" name="name" placeholder='Name' />
                                            <ErrorMessage name="name" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className='form-field'>
                                            <Field type="text" id="footer-email" name="email" placeholder='Email' />
                                            <ErrorMessage name="email" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className='form-field'>
                                            <Field type="text" id="footer-phone" name="phone" placeholder='Phone Number' />
                                            <ErrorMessage name="phone" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className='form-field'>
                                            <Field placeholder='Message' name="message" id="footer-message" ></Field>
                                            <ErrorMessage name="message" component="p" className="ErrorMessage" />
                                        </div>
                                        <button className='footer--submit'>Submit</button>
                                        <div id="SuccessMessage">

                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </div>

                        <div className="footer-address">
                            <div className="address">
                                <h3>Address</h3>
                                <address>
                                    Wellfield Road <br /> Hatfield, Herts AL10 0BS <br /> United Kingdom.
                                </address>
                            </div>
                            <div className="opening-hours">
                                <h3>Opening hours</h3>
                                <p>Monday–Friday: 9AM-5PM GMT</p>
                            </div>
                        </div>

                        <div className="footer-contact">
                            <div className="phone-div">
                                <h3>Phone</h3>
                                <a href="tel:+44 1707 262 287">+44 1707 262 287</a>
                            </div>
                            <div className="email-div">
                                <h3>Email</h3>
                                <a href='mailto:contact@caressakahn.com'>contact@caressakahn.com</a>
                            </div>
                            <div className="social-div">
                                <h3>Social Media</h3>
                                <div className="social-icons">
                                    <a href=""> <img src={process.env.PUBLIC_URL + `/images/linkedin.png`} alt="Linkedin - Caressa Kahn" /></a>
                                    <a href=""><img src={process.env.PUBLIC_URL + `/images/twitter.png`} alt="Twitter - Caressa Kahn" /></a>
                                    <a href=""> <img src={process.env.PUBLIC_URL + `/images/instagram.png`} alt="Instagram - Caressa Kahn" /></a>
                                    <a href=""><img src={process.env.PUBLIC_URL + `/images/facebook.png`} alt="Facebook - Caressa Kahn" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <div className="copywright-section container">
                <p>All content, design, and layout are Copyright © 2010 - 2024 Caressa Kahn All Rights Reserved. Reproduction in whole or part in any form or medium without specific written permission is prohibited</p>
                <div className="signatures-logo">
                    <p>Designed By</p>
                    <a href="https://www.signatures1.com/"> <img src={process.env.PUBLIC_URL + `/images/signatures-logo.svg`} alt="Signatures1 - Caressa Kahn" /></a>
                </div>

            </div>
        </>
    )
}

export default Footer