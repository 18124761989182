import React from 'react'
import './Button.css'
const Button = ({ link, content }) => {
  return (
    <>
      <a className='common-btn' href={link}>
        {content}
      </a>
    </>
  )
}

export default Button