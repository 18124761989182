import React from 'react'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import Planet from './Planet/Planet'
import Community from './Community/Community'
import ManufacturingExcellence from './ManufacturingExcellence/ManufacturingExcellence'
import Blog from '../../Components/Blog/Blog'
import { Helmet } from "react-helmet";
import CsrInner from '../../Components/CsrInner/CsrInner'


const Sustainability = () => {

    const innerBanner = {
        innerBannerImgMobile: "/images/banners/mobile/sustainability.jpg",
        innerBannerImgMobileAlt: "Sustainability banner - Caressa Kahn",
        innerBannerImg: "/images/banners/sustainability.jpg",
        innerBannerImgAlt: "Sustainability banner - Caressa Kahn",
        innerBannerHead: "Empowering Communities: Our Social Canvas",
        innerBannerSubHead: "Fostering Growth, Well-being, and Sustainability Beyond Borders",
        innerMenus: [
            { label: "Planet", path: "#planet" },
            { label: "CSR", path: "#csr" },
            { label: "Community", path: "#community" },
            { label: "Manufacturing Excellence", path: "#manufacturing-excellence" }
        ]
    }

    return (
        <>

            <Helmet>
                {/* Meta Tags */}
                <meta name="description" content="Discover Caressa Kahn's commitment to sustainability. Explore our initiatives, practices, and efforts towards a greener future. Join us in making a positive impact on the environment and society." />

                {/* Open Graph Tags */}
                <meta property="og:title" content="Sustainability - Caressa Kahn" />
                <meta property="og:description" content="Discover Caressa Kahn's commitment to sustainability. Explore our initiatives, practices, and efforts towards a greener future. Join us in making a positive impact on the environment and society." />
                <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />

                {/* Canonical Tag */}
                <link rel="canonical" href="" />
                <title>Sustainability - Caressa Kahn</title>
            </Helmet>
            <InnerBanner {...innerBanner} />
            <Planet />
            <CsrInner />
            <Community />
            <ManufacturingExcellence />
            <Blog />
        </>
    )
}

export default Sustainability
