import React from 'react'
import './Innovation.css'
import Button from '../../../Components/Button/Button'

const Innovation = () => {
    return (
        <>
            <section className='innovation-section'>
                <div className="innovation-div container">
                    <div>
                        <h2>INNOVATION ILLUMINATED</h2>
                        <h3>Pioneering Beauty Science</h3>
                    </div>
                    <div>
                        <p>At Caressa Kahn,innovation intersects with inclusivity and sustainability. Our products, designed for a diversity of skin tones, embody our commitment to every individual's beauty.</p>
                        <Button link={'/innovations'} content={'Read More'} />
                    </div>

                </div>
            </section>
        </>
    )
}

export default Innovation