import React from 'react'
import './heritage.css'
import Timeline from '../Timeline/Timeline'

const Heritage = () => {
    return (
        <>
            <section className="heritage" id="heritage">
                <div className="container padding">
                    <h2>Heritage</h2>
                    <div className="heritage-flex">
                        <div className='heritage-sec-holder'>
                            <div className="heritage-sec">
                                <h3>Legacy</h3>
                                <p>"Our Legacy" traces Caressa Kahn's transformation from its 1932 inception into a global pioneer in cosmetic accessories. Celebrating key milestones, from the invention of the flocked foam puff to advancing 'Green Science', our journey underscores a steadfast dedication to craftsmanship, innovation, and inclusivity. Each product evolution reflects our mission to serve diverse beauty needs, embodying our ethos of quality and sustainable design, and reinforcing our legacy as industry leaders committed to enhancing user experience.</p>
                            </div>
                            <div className="heritage-sec">
                                <h3>Story</h3>
                                <p>Founded in 1932 in London by Mr. H Michel, Caressa Kahn Manufacturing Company Limited, initially named L Kahn Manufacturing, originated from a partnership in the German fashion sector. Transitioning from diverse fashion production, including wartime parachutes, to a focus on cosmetic applicators, the company pioneered the first flocked foam puff. With a history rich in innovation and family involvement, Caressa Kahn, named after Michel's daughter, signifies a legacy of craftsmanship and dedication to the beauty industry. In 2010, expansion continued with a new manufacturing facility in China, enhancing its global footprint. Under new ownership since 2023, Caressa Kahn is poised for future growth while preserving its foundational expertise.    </p>
                            </div>
                        </div>
                        <div className="heritage-img">
                            <img src={process.env.PUBLIC_URL + "images/about/story.jpg"} alt="Story - Caressa Kahn" />
                        </div>
                    </div>
                    <div className="abt-video">
                        <iframe src="https://player.vimeo.com/video/58556681?title=0&byline=0&portrait=0&color=ffffff" width="100%" height="100%" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </div>

                    <Timeline />

                </div>
            </section>






        </>
    )
}

export default Heritage