import React from 'react'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import BlogList from './BlogList/BlogList'
import { Helmet } from "react-helmet";

const BlogPage = () => {
    const innerBanner = {
        innerBannerImgMobile: "/images/banners/mobile/blog.jpg",
        innerBannerImgMobileAlt: "Blog Banner - Caressa Kahn",
        innerBannerImg: "/images/banners/blog.jpg",
        innerBannerImgAlt: "Blog Banner - Caressa Kahn",
        innerBannerHead: "Unleash the Power of Caressa Kahn Insights",
        innerBannerSubHead: "Discover a world of inspiration, education, and innovation to elevate your beauty expertise.",
        innerMenus: ""
    }


    return (
        <>

            <Helmet>
                {/* Meta Tags */}
                <meta name="description" content="Explore insightful articles on Caressa Kahn's blog. Stay informed and inspired with our latest posts." />

                {/* Open Graph Tags */}
                <meta property="og:title" content="Blog - Caressa Kahn" />
                <meta property="og:description" content="Explore insightful articles on Caressa Kahn's blog. Stay informed and inspired with our latest posts." />
                <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />

                {/* Canonical Tag */}
                <link rel="canonical" href="" />
                <title>Blog - Caressa Kahn</title>
            </Helmet>

            <InnerBanner {...innerBanner} />
            <BlogList />
        </>
    )
}

export default BlogPage