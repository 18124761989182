import React from 'react'
import './blogSections.css'

const BlogSections = () => {
    return (
        <>

            <section className="blogSections">
                <div className="container padding">
                    <div className="blogSections-One-flex">
                        <div className="blogSections-One-text">
                            <h2>The Best Brown Mascaras For Natural-Looking, Defined Lashes</h2>
                            <p>Mascaras have gone beyond conventional black, embracing different shades of the colour spectrum. With brown at the peak of popularity, glance over our selection of the best brown mascaras for subtle yet defined lashes.</p>
                            <p>Brown is the new black. While a single swipe of the timeless black mascara is enough to give you fluttery, voluminous lashes, chances are, your vanity craves a more understated, soft hue for an au naturel, no-makeup makeup look. Having received the seal of approval from beauty mogul Kylie Jenner herself – who resorts to the chocolaty variant every opportunity she gets – brown mascaras are great for when you don’t seek over-dramatic results and much prefer subtler volume and dimension.</p>
                        </div>
                        <div className="blogSections-One-img">
                            <img src={process.env.PUBLIC_URL + "/images/blog/brownmascara1.jpg"} alt="" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="green">
                <div className="container padding">
                    <div className="blogSections-One-flex even--blog">
                        <div className="blogSections-One-img">
                            <img src={process.env.PUBLIC_URL + "/images/blog/brownmascara2.jpg"} alt="" />
                        </div>
                        <div className="blogSections-One-text">
                            <h2>Why Pick A Brown Mascara Over Black?</h2>
                            <p>First and foremost, brown mascara promises a natural, not-in-your-face appeal for those advocating for an everyday makeup look. If you desire length and volume sans bold colour, brown mascaras offer a less intense look while getting the job done. Besides, this chocolate shade is ideal for light-eyed beauty girls. Brown mascaras complement and accentuate the features of those with lighter eyes. Pick between a brown and black variant depending on the occasion, your eyeshadow preferences, and the kind of definition you’re looking for. While black mascaras may be ideal for special occasions, as they lend a more striking look, brown ones can give your eyelashes an understated, natural finish. As for the eyeshadow, light-neutral, warm colours pair well with brown mascara. Meanwhile, bold hues and smoky shades complement jet-black mascara. When debating between the two, take your personal style and preferences into consideration – a captivating showstopper look demands black, while a natural, barely-there makeup look is synonymous with brown.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="grey">
                <div className="container padding">
                    <div className="blogSections-One-flex">
                        <div className="blogSections-One-text">
                            <h2>The Best Brown Mascaras to Ace The ‘Clean Girl Aesthetic’</h2>
                            <p>Mascaras have gone beyond conventional black, embracing different shades of the colour spectrum. With brown at the peak of popularity, glance over our selection of the best brown mascaras for subtle yet defined lashes.</p>
                            <p>Brown is the new black. While a single swipe of the timeless black mascara is enough to give you fluttery, voluminous lashes, chances are, your vanity craves a more understated, soft hue for an au naturel, no-makeup makeup look. Having received the seal of approval from beauty mogul Kylie Jenner herself – who resorts to the chocolaty variant every opportunity she gets – brown mascaras are great for when you don’t seek over-dramatic results and much prefer subtler volume and dimension.</p>
                        </div>
                        <div className="blogSections-One-img">
                            <img src={process.env.PUBLIC_URL + "/images/blog/brownmascara3.jpg"} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="container padding back-blog">
                <img src={process.env.PUBLIC_URL + "/images/blog/arrow1.svg"} alt="" />
                <a href="/blog">Back to Blog</a>
                <img src={process.env.PUBLIC_URL + "/images/blog/arrow2.svg"} alt="" />
            </section>



        </>
    )
}

export default BlogSections