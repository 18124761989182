import React from 'react';
import './leftSection.css';

const LeftSection = (props) => {
  const { heading, paragraphs, imageSrc, altText } = props;

  return (
    <div className="left-section-flex">
      <div className="left-section-text">
        <h2>{heading}</h2>

        {paragraphs.map((paragraph, index) => (
          <div key={index} className="left-section-para">
            {paragraph.subtitle && <h3>{paragraph.subtitle}</h3>}
            <p>{paragraph.content}</p>
          </div>
        ))}
      </div>

      <div className="left-section-img">
        <img src={imageSrc} alt={altText} />
      </div>
    </div>
  );
};

export default LeftSection;
