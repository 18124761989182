import React from 'react'
import './highlight.css'

const Highlight = () => {
    return (
        <>

            <section className="highlight container padding">
                <h2>Highlights</h2>
                <div className="highlight-flex">
                    <div className="highlight-sec">
                        <h2>7000</h2>
                        <p>Lorem, ipsum dolor</p>
                    </div>
                    <div className="highlight-sec">
                        <h2>450</h2>
                        <p>lorem</p>
                    </div>
                    <div className="highlight-sec">
                        <h2>07</h2>
                        <p>lorem</p>
                    </div>
                    <div className="highlight-sec">
                        <h2>145</h2>
                        <p>lorem</p>
                    </div>
                    <div className="highlight-sec">
                        <h2>450</h2>
                        <p>lorem</p>
                    </div>
                </div>

            </section>

        </>
    )
}

export default Highlight
