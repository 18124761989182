import React from 'react'
import './HomeCareer.css';

export default function HomeCareer() {
    return (
        <div className='homepage-career'>
            <div className='career-center'>
                <h2>TALENT</h2>
            </div>
            <div className='career-link'>
                <p>Find More Jobs</p>
                <img src={process.env.PUBLIC_URL + `/images/LinkedIn_Logo 1.png`} alt="Products - Caressa Kahn" />
            </div>
        </div>
    )
}
