import React from 'react'
import './Community.css'
import RightSection from '../../../Components/RightSection/RightSection'

const Community = () => {
    const rightSection1 = {
        heading: 'Community Initiatives:',
        paragraphs: [
            {
                subtitle: '',
                content:
                    "Caressa Kahn is collaborating with Sambhavi, a sustainable fashion label from Coimbatore, India in empowering visually impaired weavers in India by     providing them with sustainable livelihoods. This partnership reflects commitment to social responsibility, inclusivity, and support for underprivileged communities, aligning with its CSR initiatives and values.",
            },
        ],
        imageSrc: '/images/sustainability/CommunityInitiatives.jpg',
        altText: 'Community Initiatives - Caressa Kahn',
    };

    return (
        <>
            <section className='community' id='community'>
                <div className="container">
                    <RightSection {...rightSection1} />
                </div>
            </section>
        </>
    )
}

export default Community
