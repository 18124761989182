import React from 'react'
import './Csr.css'
import Button from '../../Components/Button/Button'
import 'swiper/css';

const Csr = ({ isHomePage }) => {
    return (
        <>
            <section className='initiativessection' id='csr'>
                <div className="initiativesflex">
                    <div className="initiativescontent">
                        <div>
                            <h2>SUSTAINABILITY</h2>
                            <img src={process.env.PUBLIC_URL + `/images/sustainability.jpg`} alt="Compact - Caressa Kahn" />
                            <h3>Sustainability in Production</h3>
                            <p>Embracing 'The Green Science' philosophy, Caressa Kahn crafts durable, eco-conscious beauty accessories. Our commitment to sustainability is evident in our use of vegan leather, rejection of animal testing, and creation of lightweight products to minimize fuel consumption. </p>
                        </div>

                        {isHomePage && <Button link={'/sustainability'} content={'Read More'} />}
                    </div>
                    <div className='initiativescontent'>
                        <div className='initiatives'>
                            <h2></h2>
                            <img src={process.env.PUBLIC_URL + `/images/community.jpg`} alt="Compact - Caressa Kahn" />
                            <h3>Empowering Communities</h3>
                            <p>Caressa Kahn is partnering with Sambhavi, a young sustainable slow fashion label from India, championing social and economic empowerment for visually impaired weavers and underprivileged communities. </p>
                        </div>

                        {isHomePage && <Button link={'/sustainability'} content={'Read More'} />}
                    </div>
                </div>

            </section>
        </>
    )
}

export default Csr
