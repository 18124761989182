import React from 'react'
import IntroSection from '../../Components/IntroSection/IntroSection'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import ContactForm from './ContactForm/ContactForm'
import Blog from '../../Components/Blog/Blog'
import { Helmet } from "react-helmet";

const Contact = () => {

    // const innerBanner = {
    //     innerBannerImgMobile: "/images/banners/mobile/contact.jpg",
    //     innerBannerImgMobileAlt: "Contact Banner - Caressa Kahn",
    //     innerBannerImg: "/images/banners/contact.jpg",
    //     innerBannerImgAlt: "Contact Banner - Caressa Kahn",
    //     innerBannerHead: "Beyond the Brushstrokes - Your Connection to Caressa Kahn",
    //     innerBannerSubHead: "Explore collaborations, ask questions, or leave feedback .We're committed to building meaningful connections.",
    //     innerMenus: ""
    // }

    // const introSection = {
    //     introHead: "Contact",
    //     introPara: "Your connection to a world of beauty possibilities starts here. Whether you're a potential partner, curious customer, or passionate industry voice, we're eager to connect. Ask questions, share ideas, or explore collaboration opportunities. Our team is dedicated to personalized support and building meaningful relationships. Together, let's shape the future of beauty, one brushstroke at a time."
    // }

    return (
        <>

            <Helmet>
                {/* Meta Tags */}
                <meta name="description" content="Contact Caressa Kahn for inquiries and assistance. Our team is here to help with your questions and feedback. Reach out today!" />

                {/* Open Graph Tags */}
                <meta property="og:title" content="Contact Us - Caressa Kahn" />
                <meta property="og:description" content="Contact Caressa Kahn for inquiries and assistance. Our team is here to help with your questions and feedback. Reach out today!" />
                <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />

                {/* Canonical Tag */}
                <link rel="canonical" href="" />
                <title>Contact Us - Caressa Kahn</title>
            </Helmet>
            {/* <InnerBanner {...innerBanner} /> */}
            {/* <IntroSection {...introSection} /> */}
            <ContactForm />
            <Blog />
        </>
    )
}

export default Contact