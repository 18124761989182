import React from 'react';
import './rightSection.css';

const RightSection = (props) => {
    const { heading, paragraphs, imageSrc, altText } = props;

    return (
        <div className="right-section-flex">
            <div className="right-section-img">
                <img src={imageSrc} alt={altText} />
            </div>

            <div className="right-section-text">
                <h2>{heading}</h2>

                {paragraphs.map((paragraph, index) => (
                    <div key={index} className="right-section-para">
                        {paragraph.subsubtitle ? (
                            <h3 className="sub-subtitle" style={{ paddingLeft: '3rem' }}>
                                {paragraph.subsubtitle}
                            </h3>
                        ) : (
                            paragraph.subtitle && <h3>{paragraph.subtitle}</h3>
                        )}

                        {paragraph.subcontent ? (
                            <p className="sub-content" style={{ paddingLeft: '3rem' }}>
                                {paragraph.subcontent}
                            </p>
                        ) : (
                            paragraph.content &&   <p dangerouslySetInnerHTML={{ __html: paragraph.content }} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RightSection;