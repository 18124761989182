import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import './Banner.css'
import 'swiper/css';
import 'swiper/css/effect-fade'; // Import fade effect style

// Import required modules
import { Autoplay, EffectFade } from 'swiper/modules';

const Banner = () => {
    return (
        <>
            <section className='banner-section'>
                <div className="banner-slider">
                    <Swiper
                        className="banner-swiper-container"
                        effect="fade"
                        loop={true}
                        autoplay={true}
                        autoplayDisableOnInteraction={false}
                        modules={[Autoplay, EffectFade]}
                    >
                        <video
                            src={process.env.PUBLIC_URL + `/images/banner.mp4`}
                            autoPlay
                            loop
                            muted
                            playsInline
                            preload="metadata"
                            className="video"
                        ></video>
                        <SwiperSlide className="bannervideo">
                            <div className="slide-content">
                                <h2>DECADES OF DISTINCTION</h2>
                                <h3>Sublime craftmanship & legacy since 1932</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="bannervideo" >
                            <div className="slide-content">
                                <h2>INNOVATION MEETS ELEGANCE</h2>
                                <h3>Pioneering the future of cosmetic accessories</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="bannervideo">
                            <div className="slide-content">
                                <h2>BESPOKE BEAUTY CREATIONS </h2>
                                <h3>Personalized perfection for every makeup enthusiast</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="bannervideo">
                            <div className="slide-content">
                                <h2>SUSTAINABILITY IN STYLE</h2>
                                <h3>Eco-Friendly innovations shaping beauty's tomorrow</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="bannervideo">
                            <div className="slide-content">
                                <h2>THE ART OF APPLICATION</h2>
                                <h3>Revolutionizing makeup with precision & care</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="bannervideo">
                            <div className="slide-content">
                                <h2>AT THE HEART OF BEAUTY INNOVATION</h2>
                                <h3>Forged in the UK: where tradition meets tomorrow</h3>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Banner
