import React from 'react'
import './CsrInner.css'
import Button from '../../Components/Button/Button'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';

const CsrInner = ({ isHomePage }) => {
    return (
        <>
            <section className='initiatives-section padding' id='csr'>
                <div className="container ">
                    <div className="initiatives-flex">


                        <div className="initiatives-content">
                            <h2>Sustainability</h2>
                            <h3>Giving Back to Our World</h3>
                            <p>At Caressa Kahn, our dedication to beauty extends beyond our products. We are committed to making a positive impact through meaningful CSR efforts and community engagement:</p>
                            {isHomePage && <Button link={'/sustainability'} content={'View all'} />} {/* Conditionally render the button */}
                        </div>
                        <div className="initiatives-slider">
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={30}
                                scrollbar={true}
                                modules={[Scrollbar]}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    426: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1280: {
                                        slidesPerView: 2,
                                    }
                                }}
                                className='initiatives-swiper'>
                                <SwiperSlide >
                                    <div className="initiatives-slide-flex">
                                        <div className="initiatives-img-holder">
                                            <img src={process.env.PUBLIC_URL + `/images/csr-2.jpg`} alt="CSR - Caressa Kahn" />
                                        </div>
                                        <div className="initiatives-text-heading">
                                            <h3>Sustainability in Production</h3>
                                        </div>
                                        <div className="initiatives-text-p">
                                            <p>Embracing 'The Green Science' philosophy, Caressa Kahn crafts durable, eco-conscious beauty accessories. Our commitment to sustainability is evident in our use of vegan leather, rejection of animal testing, and creation of lightweight products to minimize fuel consumption. Every step reflects our dedication to reducing our carbon footprint, for a greener tomorrow.</p>
                                        </div>

                                    </div>

                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className="initiatives-slide-flex">
                                        <div className="initiatives-img-holder">
                                            <img src={process.env.PUBLIC_URL + `/images/csr-1.jpg`} alt="CSR - Caressa Kahn" />
                                        </div>
                                        <div className="initiatives-text-heading">
                                            <h3>Empowering Communities</h3>
                                        </div>
                                        <div className="initiatives-text-p">
                                            <p>Caressa Kahn is partnering with Sambhavi, a young sustainable slow fashion label from India, championing social and economic empowerment for visually impaired weavers and underprivileged communities. Sambhavi's commitment to  authenticity and community empowerment, aligns with our dedication to  making a meaningful impact through fashion. This partnership underscores  our shared values of sustainability, inclusivity, and empowerment, furthering our mission to contribute positively to society and the environment.</p>
                                        </div>
                                    </div>
                                </SwiperSlide>


                            </Swiper>
                        </div>
                    </div>
                    <p className='initiatives-flex-end-p'>These efforts are part of our broader commitment to corporate social responsibility, where we strive to create beauty that’s responsible, inclusive, and sustainable.</p>
                </div>
            </section>
        </>
    )
}

export default CsrInner
