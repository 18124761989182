import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link, useLocation } from 'react-router-dom';

export const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsMenuOpen(false); // Close the menu when the location changes

        const handleScroll = () => {
            if (location.pathname === '/' && window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <header className={`header ${location.pathname === '/' ? 'home' : ''} ${isScrolled ? 'scrolled' : ''}`} id='header'>
                <div className="header-container container">
                    <Link to="/" className={`logo ${isMenuOpen ? 'logo open' : ''}`}>
                        {/* <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" /> */}
                        <svg version="1.1" id="Layer_1" x="0px" y="0px" width={159} viewBox="0 0 427.4 159.2">
                            <g>
                                <path class="st0" d="M364.1,108.6l0,38.7l6.2,0l0-18c0,0,3.4-6.2,6-6.7c0,0,1.3,0,1.3,0.7c0.1,0.7,0.2,23.7,0.2,23.7l7.1,0
                                    l-0.2-25.4c0,0-0.6-4.9-6.6-4.7c0,0-4.1,0-7.9,3.7l-0.1-12.6l-6.1,0L364.1,108.6z"/>
                                <path class="st0" d="M404.2,91.9c-1.4,4.1-6.3,4.7-6.3,4.7l-12.3,0l14.6-46.4L388,50.3c0,0-17.7,34.7-21,40.8
                                    c-3.3,6.1-9.8,5.8-9.8,5.8l4.5-40.7l18.3-0.2l2.8-5.7c0,0-12.1,0.1-17.8,0.2c-5.7,0-7.5,6.2-7.5,6.2S351.4,83.1,351,85
                                    c-0.4,1.8-1.2,1.9-1.2,1.9L329.6,97h-5.5c3.2-5.4,0.8-9.5,0.8-9.5l-0.8-1.3c-4.5-8.2-3.6-13.6-3.5-17.7c0.1-4.1,4.9-14.8,6-17.5
                                    c1.1-2.8,0.7-4.9,0.7-4.9c0.3-6.3-6.3-7.5-6.3-7.5c-7.3-0.5-8.2,3.8-9.6,6.9c-1.4,3.1-2.1,16.7-2.1,16.7l-37.1,35.2l-4.3-0.1
                                    c3.2-5.5,0.7-9.5,0-10.7c-0.7-1.2-1.7-3.4-2.8-6.7c-1.1-3.3-1-7.8-0.5-12.7c0.5-4.9,5.2-14,5.9-16.5c0.7-2.5,0.5-5.4,0.5-5.4
                                    c-1-5.9-6.3-6.5-6.3-6.5c-7.5-0.6-8.8,4.8-10.1,8.9c-1.3,4.1-1.6,14.9-1.6,14.9l-35.4,33.1c-3.6,2.3-6.2,2.1-6.2,2.1l-21.3,0.1
                                    l4.5-12.1l31-21.2c3.9-3.7,1.8-8.7,1.8-8.7c-1.2-4.3-6.7-4.7-6.7-4.7s-8.5,0-15,0c-6.4,0-10.9,6.9-10.9,6.9l-11,29.3
                                    c0,0-9.2,6.5-12.5,8.6c-3.4,2.1-6.4,1.9-6.4,1.9l-5.7,0L175,55.2c1.1-3.9-3.2-3.9-3.2-3.9l-21.8,0.1l5.1-9.1c0,0-9,0-10.8,0
                                    c-1.8,0-2.6,1.4-2.6,1.4l-8.1,14.4c-1,2.9,1.9,3.8,1.9,3.8l2.6,0.1c0,0-15.7,27.9-17.8,31.4c-2.1,3.5-4.2,4.1-5.7,4.5
                                    c-1.6,0.4-12.8,0.1-12.8,0.1l14.6-46.4l-12.3,0.1c0,0-18.2,36-21.3,41.4c-3.1,5.4-9.5,5.1-9.5,5.1l4.4-40.7L96,57.5l2.7-5.7
                                    c0,0-11.8,0.1-17.4,0.1c-5.6,0-7.7,5.4-7.7,5.4L67,86.8c-2.1,9.5-11.8,10.1-11.8,10.1s-16.8,0.2-19.6,0.2c-2.8,0-4.5-1.5-4.5-1.5
                                    c-4.4-3.1-3.2-8.2-3.2-8.2l15.7-66.3c0.1-1,2-1,2-1l35.7,3.9L84.1,11c0,0-29.9,0.1-34.9,0.2c-5,0.1-7.6,2.9-8.5,3.7
                                    c-0.9,0.9-1.9,3.3-1.9,3.3s-25,64.6-26.4,69c-1.4,4.4,1.7,10.3,1.7,10.3c4,6.2,10.7,6.3,10.7,6.3s27.9-0.1,32.7-0.2
                                    c4.8-0.1,9.8-4.4,9.8-4.4c1.1,3.2,4.3,4.3,4.3,4.3c1.9,0,6.8-0.4,6.8-0.4c4.6-0.5,7.9-4.8,8.3-5.4c0.4-0.7,3.3-6,3.3-6l10.2-19.5
                                    c0,0-5.5,17-6.6,20.5c-1.1,3.5,0.7,6.4,0.7,6.4c2.1,4.1,6.4,4.1,6.4,4.1s12.1-0.1,16.2-0.1c4,0,6.6-4.6,6.6-4.6L144,62.1l16.8-0.1
                                    l-11.6,31c-1,3.6,1.3,6.6,1.3,6.6c2.3,3.4,5.9,3.5,5.9,3.5s5.2,0,9.1-0.1c3.9-0.1,6.9-2.1,6.9-2.1l8.4-5.7
                                    c-1.2,6.9,5.4,7.7,5.4,7.7l31.8-0.2l36-33.8c0,0,0.5,4.4,1.3,7.5c0.8,3.1,5.1,8.4,8.1,12.6c3,4.2-0.7,7.2-0.7,7.2
                                    c-2.3,2.4-5.6,0.9-5.6,0.9c-2.6-1.1-4.1-1.8-4.1-1.8c-9.5-3.3-13.2-2.5-13.2-2.5c-4.5,0.2-8.3,3.4-8.3,3.4
                                    c-3.2,2.1-2.5,6.4-2.5,6.4l45.4-0.2l35.9-33.8c-0.3,8.6,5.2,14.5,6,15.4c0.8,0.9,1.9,2.6,3.9,5.5c1.9,3-0.8,6.1-0.8,6.1
                                    c-2.3,3.5-8,0.3-8,0.3c-8.8-3.7-14.7-3.4-14.7-3.4c-4.7-0.1-9.2,3.8-9.2,3.8c-2.6,1.9-1.8,6.2-1.8,6.2l48.3-0.2l14.7-7.4
                                    c-1.4,7.5,5.5,7.2,5.5,7.2s0.2,0,7-0.1c6.7-0.1,10.8-7.7,10.8-7.7l12-23.4c0,0-5.5,16.9-6.6,20.5c-1.1,3.6,0.9,6.7,0.9,6.7
                                    c1.5,2.9,5.8,3.7,5.8,3.7l21.7,0c4,0,6.2-3.6,6.2-3.6l3.4-6.2L404.2,91.9z M204.1,60.2c1.2-3.3,4.4-3.5,4.4-3.5s4.4,0,6.5,0
                                    c2.1,0,3.9,2.2,3.9,2.2c1.6,1.9-0.6,4.9-0.6,4.9l-20.7,14.1C197.5,77.9,203,63.5,204.1,60.2"/>
                                <path class="st0" d="M310.1,108.6l-6.3,0l0,38.8h5.1l0-14.8l2.7-0.1c0,0,5.3,10.2,13.1,15.7l4.8-4c0,0-9.8-7-14.8-15.8
                                    c0,0,10.6-9.9,13.2-19.8l-6.5,0c0,0-3.3,10.4-11.2,16.7L310.1,108.6z"/>
                                <path class="st0" d="M394.6,147l6.1,0l0-17.8c0,0,2.2-5.4,6-6.6c0,0,1.3-0.1,1.4,1c0.1,0.7,0.2,23.3,0.2,23.3l7.2,0l-0.2-25.1
                                    c0,0-0.2-5.2-7-4.7c0,0-3.3-0.5-7.9,3.7l0.1-2.7l-6,0L394.6,147z"/>
                                <path class="st0" d="M356.2,119.2l-4.4-0.1v0.8c-2.2-0.7-2.6-1.3-7.4-1c-4.8,0.3-8.6,4.6-8.6,4.6c-4.4,4.9-3.5,11.7-3.5,11.7
                                    c1,6.6,7.2,10.2,7.2,10.2c3.4,1.9,10,0,10,0l1.1,1.3l5,0l0-2.2c-4-13.8,0.5-21.6,0.5-21.6L356.2,119.2z M348.9,140.7
                                    c0,0-4.3,1-5.9,0.5c-1.6-0.5-4.9-2.9-4.7-7.5c0,0-0.3-4.5,1.8-7.7c0,0,2.2-3.7,5.1-3.3c0,0,1.9,0.1,3.6,1.8
                                    C348.9,124.5,346.6,133.7,348.9,140.7"/>
                            </g>
                        </svg>
                    </Link>
                    <div className="hamburger">
                        <div className='hamburger-btns'>
                            <div className={`three-dots ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                            <div className={`close-btn ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                                <img src={process.env.PUBLIC_URL + '/images/close-img.png'} alt="Close - Caressa Kahn" />
                            </div>
                        </div>

                        <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
                            <div className="menulist">
                                <ul className='menu-list-ul'>
                                    <li><Link className='navlink' to="/about">ABOUT US</Link></li>
                                    <li><Link className='navlink' to="/product">PRODUCTS</Link></li>
                                    <li><Link className='navlink' to="/innovations">INNOVATION</Link></li>
                                    <li><Link className='navlink' to="/sustainability">SUSTAINABILITY</Link></li>
                                    <li><Link className='navlink' to="/career">CAREERS</Link></li>
                                    <li><Link className='navlink' to="/contact">CONTACT</Link></li>
                                </ul>
                                <Link to="/contact"><img src={process.env.PUBLIC_URL + '/images/contact.svg'} alt="Contact - Caressa Kahn" /></Link>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
