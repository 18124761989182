import React, { useState } from 'react';
import './contactForm.css'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().matches(/^\d{10}$/, 'Mobile must be a 10-digit number').required('Mobile is required'),
    enquiryType: Yup.string().required('Enquiry Type is required'),
    message: Yup.string().required('Message is required'),
});

const ContactForm = () => {
    const [showPreloader, setShowPreloader] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {

            setShowPreloader(true);

            const response = await fetch('http://localhost:3001/api/enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            const data = await response.json();

            // Handle the response from the server
            if (data === 'success') {
                document.getElementById('SuccessMessage').innerHTML = "Form Submitted successfully!";
                document.getElementById('SuccessMessage').style.color = "green";
            }

            setTimeout(() => {
                document.getElementById('SuccessMessage').innerHTML = '';
            }, 3000);

            document.getElementById('contact-form').reset();

        } catch (error) {
            console.error('Error:', error);
            document.querySelector(".errormess").innerHTML = 'Form not Submitted!';
            setShowPreloader(false);
        } finally {
            setShowPreloader(false);
            setSubmitting(false);
        }

        console.log('success')

    };

    return (
        <>
            <section className="contact--form">
                <div className="container">
                    <h2>Contact Us</h2>
                    <div className="contact-flex">

                        <div style={{ display: showPreloader ? 'flex' : 'none' }} className='preloader-holder'>
                            <img src={process.env.PUBLIC_URL + `/images/1490.gif`} alt="Loader" />
                        </div>
                        <div className="contact-form-sec">
                            <Formik

                                initialValues={{
                                    name: '',
                                    email: '',
                                    phone: '',
                                    enquiryType: '',
                                    message: ''
                                }}

                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}>
                                {({ }) => (
                                    <Form action="" method="POST" id='contact-form' className="contact-form">
                                        <div className='form-field'>
                                            <Field type="text" id="name" name="name" placeholder="Name" />
                                            <ErrorMessage name="name" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className='form-field'>
                                            <Field type="email" id="email" name="email" placeholder="Email" />
                                            <ErrorMessage name="email" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className='form-field'>
                                            <Field type="tel" id="phone" name="phone" placeholder="Phone" />
                                            <ErrorMessage name="phone" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className='form-field'>
                                            <Field as="select" id="enquiry" name="enquiryType">
                                                <option value="">Select</option>
                                                <option value="general">General Inquiries</option>
                                                <option value="customerService">Customer Service</option>
                                                <option value="getQuote">Get a Quote</option>
                                            </Field>
                                            <ErrorMessage name="enquiryType" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className='form-field'>
                                            <Field type="text" id="message" name="message" placeholder="Message" />
                                            <ErrorMessage name="message" component="p" className="ErrorMessage" />
                                        </div>
                                        <button type="submit" className="contact-submit">Submit</button>
                                        <div id="SuccessMessage">

                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="contact-text">
                            <address>Wellfield Road<br />
                                Hatfield, Herts AL10 0BS<br />
                                United Kingdom.</address>


                            <a href="tel:+44 1707 262 287">Tel: +44 1707 262 287</a>

                            <a href="mailto:contact@caressakahn.com">Email: contact@caressakahn.com</a>
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default ContactForm