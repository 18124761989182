import React, { useState } from 'react';
import './timeline.css';

const Timeline = () => {
    const [activeDot, setActiveDot] = useState('dot1');

    const dotChange = (dotId) => {
        setActiveDot(dotId);
    };

    return (
        <>
            <section className="timeline padding">
                <h2>Milestone</h2>

                {/* desktop */}
                <div className="growth-flex">
                    <div className='growth--sec'>
                        <p id='t-para1' className={activeDot === 'dot1' ? 'active-para' : 'hidden-para'}>
                            The business was established
                        </p>
                        <p id='t-para2' className={activeDot === 'dot2' ? 'active-para' : 'hidden-para'}>
                            We developed the first flocked powder puff for Max factor
                        </p>
                        <p id='t-para3' className={activeDot === 'dot3' ? 'active-para' : 'hidden-para'}>
                            We built our current UK production site in Hatfield
                        </p>
                        <p id='t-para4' className={activeDot === 'dot4' ? 'active-para' : 'hidden-para'}>
                            L. Kahn Manufacturing and Caressa London re-branded as Caressa Kahn
                        </p>
                        <p id='t-para5' className={activeDot === 'dot5' ? 'active-para' : 'hidden-para'}>

                            China (Joint Venture) China plant awarded SA8000 certification
                        </p>
                        <p id='t-para6' className={activeDot === 'dot6' ? 'active-para' : 'hidden-para'}>
                            CKC 100% owned China plant obtains SA8000 certification
                        </p>
                        <p id='t-para7' className={activeDot === 'dot7' ? 'active-para' : 'hidden-para'}>
                            Caressa Kahn becomes part of the Mahasakthi group
                        </p>

                    </div>

                    <div className="timeline-holder">
                        <img src={process.env.PUBLIC_URL + "/images/about/timeline.svg"} alt="Timeline line - Caressa Kahn" />

                        <div className={`year-sec year-holder1 ${activeDot === 'dot1' ? 'active-dot' : ''}`} id='dot1' onMouseEnter={() => dotChange('dot1')}>
                            <div className="timeline-dot"></div>
                            <p>1932</p>
                        </div>

                        <div className={`year-sec year-holder2 ${activeDot === 'dot2' ? 'active-dot' : ''}`} id='dot2' onMouseEnter={() => dotChange('dot2')}>
                            <div className="timeline-dot"></div>
                            <p>1950</p>
                        </div>

                        <div className={`year-sec year-holder3 ${activeDot === 'dot3' ? 'active-dot' : ''}`} id='dot3' onMouseEnter={() => dotChange('dot3')}>
                            <div className="timeline-dot"></div>
                            <p>1953</p>
                        </div>

                        <div className={`year-sec year-holder4 ${activeDot === 'dot4' ? 'active-dot' : ''}`} id='dot4' onMouseEnter={() => dotChange('dot4')}>
                            <div className="timeline-dot"></div>
                            <p>2001</p>
                        </div>

                        <div className={`year-sec year-holder5 ${activeDot === 'dot5' ? 'active-dot' : ''}`} id='dot5' onMouseEnter={() => dotChange('dot5')}>
                            <div className="timeline-dot"></div>
                            <p>2008</p>
                        </div>

                        <div className={`year-sec year-holder6 ${activeDot === 'dot6' ? 'active-dot' : ''}`} id='dot6' onMouseEnter={() => dotChange('dot6')}>
                            <div className="timeline-dot"></div>
                            <p>2012</p>
                        </div>

                        <div className={`year-sec year-holder7 ${activeDot === 'dot7' ? 'active-dot' : ''}`} id='dot7' onMouseEnter={() => dotChange('dot7')}>
                            <div className="timeline-dot"></div>
                            <p>2023</p>
                        </div>
                    </div>
                </div>

                {/* tab to mobile */}
                <div className="growth-flex-mobile">
                    <div className="timeline-holder">
                        <img src={process.env.PUBLIC_URL + "/images/about/timeline.svg"} alt="Timeline line - Caressa Kahn" />

                        <div className={`year-sec year-holder1 ${activeDot === 'dot1' ? 'active-dot' : ''}`} id='dot1' onMouseEnter={() => dotChange('dot1')}>
                            <div className="timeline-dot"></div>
                        </div>

                        <div className={`year-sec year-holder2 ${activeDot === 'dot2' ? 'active-dot' : ''}`} id='dot2' onMouseEnter={() => dotChange('dot2')}>
                            <div className="timeline-dot"></div>
                        </div>

                        <div className={`year-sec year-holder3 ${activeDot === 'dot3' ? 'active-dot' : ''}`} id='dot3' onMouseEnter={() => dotChange('dot3')}>
                            <div className="timeline-dot"></div>
                        </div>

                        <div className={`year-sec year-holder4 ${activeDot === 'dot4' ? 'active-dot' : ''}`} id='dot4' onMouseEnter={() => dotChange('dot4')}>
                            <div className="timeline-dot"></div>
                        </div>

                        <div className={`year-sec year-holder5 ${activeDot === 'dot5' ? 'active-dot' : ''}`} id='dot5' onMouseEnter={() => dotChange('dot5')}>
                            <div className="timeline-dot"></div>
                        </div>

                        <div className={`year-sec year-holder6 ${activeDot === 'dot6' ? 'active-dot' : ''}`} id='dot6' onMouseEnter={() => dotChange('dot6')}>
                            <div className="timeline-dot"></div>
                        </div>

                        <div className={`year-sec year-holder7 ${activeDot === 'dot7' ? 'active-dot' : ''}`} id='dot7' onMouseEnter={() => dotChange('dot7')}>
                            <div className="timeline-dot"></div>
                        </div>
                    </div>
                    <div className='growth--sec'>
                        <p id='t-para1' className={activeDot === 'dot1' ? 'active-para' : 'hidden-para'}>
                            1932 <br />
                            The business was established
                        </p>
                        <p id='t-para2' className={activeDot === 'dot2' ? 'active-para' : 'hidden-para'}>
                            1950<br />
                            We developed the first flocked powder puff for Max factor
                        </p>
                        <p id='t-para3' className={activeDot === 'dot3' ? 'active-para' : 'hidden-para'}>
                            1953<br />
                            We built our current UK production site in Hatfield
                        </p>
                        <p id='t-para4' className={activeDot === 'dot4' ? 'active-para' : 'hidden-para'}>
                            2001<br />
                            L. Kahn Manufacturing and Caressa London re-branded as Caressa Kahn
                        </p>
                        <p id='t-para5' className={activeDot === 'dot5' ? 'active-para' : 'hidden-para'}>
                            2008<br />
                            China (Joint Venture) China plant awarded SA8000 certification
                        </p>
                        <p id='t-para6' className={activeDot === 'dot6' ? 'active-para' : 'hidden-para'}>
                            2012<br />
                            CKC 100% owned China plant obtains SA8000 certification
                        </p>
                        <p id='t-para7' className={activeDot === 'dot7' ? 'active-para' : 'hidden-para'}>
                            2023<br />
                            Caressa Kahn becomes part of the Mahasakthi group
                        </p>

                    </div>
                </div>
            </section>



        </>
    );
};

export default Timeline;
