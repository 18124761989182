import React from 'react'
import './governance.css'

const Governance = () => {
    return (
        <>
            <section className="governance" id="governance-ethics">
                <div className="container padding">
                    <h2>Governance & Ethics</h2>
                    <div className="governance-flex">
                        <div className="governance-sec">
                            <img src={process.env.PUBLIC_URL + "/images/about/profile.jpg"} alt="Profile - Caressa Kahn" className='governance-img' />
                            <div className="governance-text">
                                <h2>Richard Michel</h2>
                                <h3>Managing Director</h3>
                            </div>
                        </div>
                        <div className="governance-sec">
                            <img src={process.env.PUBLIC_URL + "/images/about/profile.jpg"} alt="Profile - Caressa Kahn" className='governance-img' />
                            <div className="governance-text">
                                <h2>Richard Michel</h2>
                                <h3>Managing Director</h3>
                            </div>
                        </div>
                        <div className="governance-sec">
                            <img src={process.env.PUBLIC_URL + "/images/about/profile.jpg"} alt="Profile - Caressa Kahn" className='governance-img' />
                            <div className="governance-text">
                                <h2>Richard Michel</h2>
                                <h3>Managing Director</h3>
                            </div>
                        </div>
                        <div className="governance-sec">
                            <img src={process.env.PUBLIC_URL + "/images/about/profile.jpg"} alt="Profile - Caressa Kahn" className='governance-img' />
                            <div className="governance-text">
                                <h2>Richard Michel</h2>
                                <h3>Managing Director</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Governance