import React from 'react';
import './productsSec.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Autoplay, Navigation, EffectFade } from 'swiper/modules';


const ProductsSec = () => {
  const products = [
    {
      name: 'Puff',
      image: [
        '/images/products/puff/puff1.jpg',
        '/images/products/puff/puff2.jpg',
        '/images/products/puff/puff3.jpg',
        '/images/products/puff/puff4.jpg',
        '/images/products/puff/puff5.jpg',
        '/images/products/puff/puff6.jpg',
        '/images/products/puff/puff7.jpg',
        '/images/products/puff/puff8.jpg',
        '/images/products/puff/puff9.jpg',
        '/images/products/puff/puff10.jpg',
        '/images/products/puff/puff11.jpg',
        '/images/products/puff/puff12.jpg',
        '/images/products/puff/puff13.jpg',
        '/images/products/puff/puff14.jpg',
        '/images/products/puff/puff15.jpg',
        '/images/products/puff/puff16.jpg',
        '/images/products/puff/puff17.jpg',
        '/images/products/puff/puff18.jpg',
        '/images/products/puff/puff19.jpg',
        '/images/products/puff/puff20.jpg',
        '/images/products/puff/puff21.jpg',
        '/images/products/puff/puff22.jpg',
        '/images/products/puff/puff23.jpg',
        '/images/products/puff/puff24.jpg',
        '/images/products/puff/puff25.jpg',
        '/images/products/puff/puff26.jpg',
        '/images/products/puff/puff27.jpg',
        '/images/products/puff/puff28.jpg',
        '/images/products/puff/puff29.jpg',
        '/images/products/puff/puff30.jpg',
        '/images/products/puff/puff31.jpg',
        '/images/products/puff/puff32.jpg',
        '/images/products/puff/puff33.jpg',
        '/images/products/puff/puff34.jpg',
        '/images/products/puff/puff35.jpg',
        '/images/products/puff/puff36.jpg',
        '/images/products/puff/puff37.jpg',
        '/images/products/puff/puff38.jpg',
        '/images/products/puff/puff39.jpg',
        '/images/products/puff/puff40.jpg',
        '/images/products/puff/puff41.jpg',
        '/images/products/puff/puff42.jpg',
        '/images/products/puff/puff43.jpg',
        '/images/products/puff/puff44.jpg',
        '/images/products/puff/puff45.jpg',
        '/images/products/puff/puff46.jpg',
        '/images/products/puff/puff47.jpg',
        '/images/products/puff/puff48.jpg',
        '/images/products/puff/puff49.jpg',
        '/images/products/puff/puff50.jpg',
        '/images/products/puff/puff51.jpg',
        '/images/products/puff/puff52.jpg',
        '/images/products/puff/puff53.jpg',
        '/images/products/puff/puff54.jpg',
        '/images/products/puff/puff55.jpg',
        '/images/products/puff/puff56.jpg',
        '/images/products/puff/puff57.jpg',
        '/images/products/puff/puff58.jpg',
        '/images/products/puff/puff59.jpg',
      ],
      description: 'Our flocked foam powder puffs, a pioneering invention, cater to all market sectors. These puffs are designed for excellent application properties across various makeup forms, including pressed and loose powders, concealers, liquid, and compact foundations. The puffs are available in multiple sizes, ranging from 20mm to 150mm, with customizable flock fibers and materials.'
    },

    {
      name: 'Pouchettes',
      image: [
        '/images/products/pouch/pouch1.jpg',
        '/images/products/pouch/pouch2.jpg'
      ],
      description: 'Custom-designed for elegance and functionality, our pouchettes are crafted to hold and protect cosmetic essentials. Utilizing durable and stylish materials, these pouchettes combine practicality with aesthetic appeal, making them a must-have accessory for makeup enthusiasts.',
    },
    {
      name: 'Applicators',
      image: [
        '/images/products/applicators/applicators1.jpg',
        '/images/products/applicators/applicators2.jpg',
        '/images/products/applicators/applicators3.jpg',
        '/images/products/applicators/applicators4.jpg',
        '/images/products/applicators/applicators5.jpg',
        '/images/products/applicators/applicators6.jpg',
        '/images/products/applicators/applicators7.jpg',
        '/images/products/applicators/applicators8.jpg',
        '/images/products/applicators/applicators9.jpg',
        '/images/products/applicators/applicators10.jpg',
      ],
      description: 'From foam-tipped to brush-based, our applicators are engineered for precision and versatility. Available in a wide range of shapes, sizes, and materials, these applicator sensure flawless makeup application, catering to diverse consumer needs and preferences.',
    },
    {
      name: 'Cushion Puffs',
      image: [
        '/images/products/Cushion Puffs.jpg'

      ],
      description: 'Specially designed for use with cushion compact formulas, these puffs offer a soft, even application. Their unique structure and material composition ensure a seamless blend, enhancing the makeup experience with every touch.',
    },
    {
      name: 'Cellulose Sponges',
      image: [
        '/images/products/sponge/sponge1.jpg',
        '/images/products/sponge/sponge2.jpg',
        '/images/products/sponge/sponge3.jpg'
      ],
      description: 'Made from natural materials, these sponges are ideal for cleansing and exfoliating, providing a gentle yet effective skincare routine. Their biodegradable nature underscores our commitment to sustainability.',
    },
    {
      name: 'Flocked Cushion Applicators',
      image: [
        '/images/products/brush3/brush3-1.jpg',
        '/images/products/brush3/brush3-2.jpg',
        '/images/products/brush3/brush3-4.jpg',
      ],
      description: "Integrating the latest in flocking technology, these applicators deliver precision and comfort. They're designed to work seamlessly with cushion compacts, offering an upgraded beauty tool for an impeccable finish.",
    },
    {
      name: 'Cushion Compact Inserts',
      image: [
        '/images/products/Cushion Compact Inserts.jpg',
      ],
      description: 'Our cushion compact inserts are designed for ease of use and portability. These inserts not only protect the product but also ensure its longevity, enhancing the overall user experience.',
    },
    {
      name: 'Powder Sifters',
      image: [
        '/images/products/powdershifter/powdershifter1.jpg',
        '/images/products/powdershifter/powdershifter2.jpg',
      ],
      description: 'These sifters are engineered for precise powder distribution, minimizing waste and ensuring an even application. Their design and functionality reflect our innovative approach to makeup accessory development.',
    },
    {
      name: 'Flocked Tips',
      image: [
        '/images/products/brush2/brush2-1.jpg',
        '/images/products/brush2/brush2-2.jpg',
        '/images/products/brush2/brush2-3.jpg',
        '/images/products/brush2/brush2-4.jpg',
        '/images/products/brush2/brush2-5.jpg',
        '/images/products/brush2/brush2-6.jpg',
        '/images/products/brush2/brush2-7.jpg',
        '/images/products/brush2/brush2-8.jpg',
        '/images/products/brush2/brush2-9.jpg',
        '/images/products/brush2/brush2-10.jpg',
      ],
      description: 'Offering precision for detailed makeup application, flocked tips are ideal for eye makeup and other intricate areas. Their design ensures a smooth, precise application, embodying our dedication to innovation.',
    },
    {
      name: 'Brushes',
      image: [
        '/images/products/brush1/brush1-1.jpg',
        '/images/products/brush1/brush1-2.jpg',
        '/images/products/brush1/brush1-3.jpg',
        '/images/products/brush1/brush1-4.jpg',
      ],
      description: "Partnering with Dupont for synthetic materials, our brushes span all shapes, sizes, and specifications. Whether natural or synthetic, these brushes are tailored for performance, quality, and durability, catering to every makeup application need.",
    },

  ];
  return (
    <>

      <section className="products" id="products">
        <div className="container padding">
          <div className="products-intro">
            <h2>Products</h2>
            <p>Discover our premium cosmetic accessories, where quality meets innovation. Our product range, from pioneering puffs to advanced brushes, embodies our dedication to R&D, sustainability, and beauty excellence. Explore creations designed for every makeup need, crafted at the intersection of tradition and technological advancement.</p>
          </div>

          <div className="products-sec-flex">
            {products.map((product, index) => (
              <div key={index} className="products-section">
                <Swiper
                  className="mySwiper viewProducts"
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  navigation={{
                    prevEl: '.custom-prev-button',
                    nextEl: '.custom-next-button',
                  }}
                  modules={[Autoplay, Navigation, EffectFade]} // Include the Fade module
                  effect="fade"
                  loop={true}
                  fadeEffect={{ crossFade: true }}
                  slidesPerView={1} // Only 1 slide visible at a time
                  spaceBetween={20} // Adjust as needed
                >
                  {product.image.map((image, imageIndex) => (
                    <SwiperSlide key={imageIndex}>
                      <img src={process.env.PUBLIC_URL + image} alt={`Products - ${product.name}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="products-section-text">
                  <h3>{product.name}</h3>
                  <p>{product.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>


    </>
  )
}

export default ProductsSec