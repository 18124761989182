import React from 'react'
import './Legacy.css'
import Button from '../../../Components/Button/Button'
const Legacy = () => {
    return (
        <>
            <section className='legacy-section padding'>
                <div className="legacy-container container">
                    <div className="legacy-header">
                        <h2>CRAFT & CHRONICLE</h2>
                        <h3>The Caressa Kahn Legacy</h3>
                    </div>
                    <div className="legacy-content">
                        <p>Since its inception in 1932 by H Michel, Caressa Kahn has evolved from a humble London shop into a global hub of innovation in cosmetic accessories. As pioneers of the world's first flocked foam puff, our heritage is marked by groundbreaking achievements.</p>
                    </div>
                    <Button link={'/about'} content={'Read More'} />
                </div>
            </section>
        </>
    )
}

export default Legacy
