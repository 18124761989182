import React from 'react'
import { Helmet } from "react-helmet";
import Banner from './Banner/Banner'
import Legacy from './Legacy/Legacy'
import Innovation from './Innovation/Innovation'
import Canvas from './Canvas/Canvas'
import Csr from '../../Components/Csr/Csr'
import Blog from '../../Components/Blog/Blog'
import HomeCareer from './HomeCareer/HomeCareer'

const Home = () => {
  return (
    <>

      <Helmet>
        {/* Meta Tags */}
        <meta name="description" content="Welcome to Caressa Kahn - Discover innovative solutions tailored to you. Explore now." />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Home - Caressa Kahn" />
        <meta property="og:description" content="Welcome to Caressa Kahn - Discover innovative solutions tailored to you. Explore now." />
        <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />

        {/* Canonical Tag */}
        <link rel="canonical" href="" />
        <title>Home - Caressa Kahn</title>
      </Helmet>

      <Banner />
      <Legacy />
      <Canvas />
      <Innovation />
      <Csr isHomePage={true} />
      <HomeCareer />
      <Blog />
    </>
  )
}

export default Home
