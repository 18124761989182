import React from 'react'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import AboutOverview from './AboutOverview/AboutOverview'
import Heritage from './Heritage/Heritage'
import Governance from './Governance/Governance'
import Safety from './Safety/Safety'
import Blog from '../../Components/Blog/Blog'
import { Helmet } from "react-helmet";
import Highlight from './Highlight/Highlight'

const About = () => {

  const innerBanner = {
    innerBannerImgMobile: "/images/banners/mobile/aboutus1.jpg",
    innerBannerImgMobileAlt: "About Banner - Caressa Kahn",
    innerBannerImg: "/images/banners/aboutus1.jpg",
    innerBannerImgAlt: "About Banner - Caressa Kahn",
    innerBannerHead: "Crafting Elegance, Shaping Futures",
    innerBannerSubHead: "Discover the Legacy of Caressa Kahn: Pioneers in Beauty Innovation Since 1932",
    innerMenus: [
      { label: "About", path: "#about" },
      { label: "Heritage", path: "#heritage" },
      { label: "Governance & Ethics", path: "#governance-ethics" },
      { label: "Quality & Safety Side", path: "#quality-safety" }
    ]
  }
  return (
    <>
      <Helmet>
        {/* Meta Tags */}
        <meta name="description" content="Learn about Caressa Kahn - our vision, mission, and dedication to Caressa Kahn. Discover our journey, meet our team, and find out how we're making a difference." />

        {/* Open Graph Tags */}
        <meta property="og:title" content="About Us - Caressa Kahn" />
        <meta property="og:description" content="Learn about Caressa Kahn - our vision, mission, and dedication to Caressa Kahn. Discover our journey, meet our team, and find out how we're making a difference." />
        <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />

        {/* Canonical Tag */}
        <link rel="canonical" href="" />
        <title>About Us - Caressa Kahn</title>
      </Helmet>



      <InnerBanner {...innerBanner} />
      <AboutOverview />
      <Heritage />
      <Highlight />
      <Governance />
      <Safety />
      <Blog />
    </>
  )
}

export default About