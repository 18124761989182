import React from 'react'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import BlogSections from './BlogSections/BlogSections'
import Blog from '../../Components/Blog/Blog'

const BlogOne = () => {

    const innerBanner = {
        innerBannerImgMobile: "/images/banners/mobile/blog.jpg",
        innerBannerImgMobileAlt: "Blog Banner - Caressa Kahn",
        innerBannerImg: "/images/banners/blog.jpg",
        innerBannerImgAlt: "Blog Banner - Caressa Kahn",
        innerBannerHead: "Unleash the Power of Caressa Kahn Insights",
        innerBannerSubHead: "Discover a world of inspiration, education, and innovation to elevate your beauty expertise.",
        innerMenus: ""
    }


    return (
        <>
            <InnerBanner {...innerBanner} />
            <BlogSections />
            <Blog />
        </>
    )
}

export default BlogOne