import React from 'react'
import './manufacturing.css'
import LeftSection from '../../../Components/LeftSection/LeftSection'
import RightSection from '../../../Components/RightSection/RightSection'

const Manufacturing = () => {
    const rightSection1 = {
        heading: 'Product-Specific Processes:',
        paragraphs: [
            {
                subtitle: 'Foam slicing to automated flocking for diverse product range:',
                content:
                    "Caressa Kahn’s integration of foam slicing and automated flocking processes enables the creation of a wide array of cosmetic applicators. This seamless blend of technology ensures each product caters to specific makeup application needs, enhancing texture uniformity and user experience.",
            },
            {
                subtitle: 'Advanced flocking technology for texture and application precision: ',
                content:
                    "Leveraging cutting-edge flocking technology, Caressa Kahn achieves unparalleled precision in texture and application across its product line. This innovative approach allows for the customization of product surfaces, ensuring superior performance and a flawless finish for every makeup enthusiast.",
            },
        ],
        imageSrc: '/images/products/productspecificprocess.jpg',
        altText: 'Product-Specific Processes - Caressa Kahn',
    };
    const rightSection2 = {
        heading: 'Quality Practices',
        paragraphs: [
            {
                subtitle: 'Rigorous quality control at every production stage:',
                content:
                    "Caressa Kahn implements strict quality control measures at each stage of production, ensuring that every product not only meets but exceeds industry standards. This meticulous approach guarantees the integrity, safety, and excellence of their cosmetic applicators, reflecting their unwavering dedication to quality.",
            },
            {
                subtitle: 'Commitment to sustainability and quality across the product lifecycle: ',
                content:
                    "Caressa Kahn's commitment extends beyond mere production, emphasizing sustainability and quality throughout the product lifecycle. By integrating eco-friendly practices and materials, the company ensures that its products are not only of the highest caliber but also align with environmental and ethical standards, setting a benchmark for responsible beauty manufacturing.",
            },
        ],
        imageSrc: '/images/products/quality.jpg',
        altText: 'Quality Practices - Caressa Kahn',
    };




    return (
        <>

            <section className="manufacturing-products" id="processes">
                <div className="container">
                    <LeftSection {...rightSection1} />
                </div>
            </section>

            <section className="quality--process" id="quality-practices">
                <div className="container">
                    <RightSection {...rightSection2} />
                </div>
            </section>

        </>
    )
}

export default Manufacturing