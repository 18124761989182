import React from 'react';
import { Link } from 'react-router-dom';
import "./blogList.css";

const BlogList = () => {
    const blogs = [
        {
            name: 'Puff',
            link: '/blog/blog-one',
            image: '/images/blog/blog1.jpg',
            description: 'Our flocked foam powder puffs, a pioneering invention, cater to all market sectors.',
        },
        {
            name: 'Pouchettes',
            link: '/blog/blog-one',
            image: '/images/blog/blog2.jpg',
            description: 'Custom-designed for elegance and functionality, our pouchettes are crafted to hold and protect cosmetic essentials. ',
        },
        {
            name: 'Applicators',
            link: '/blog/blog-one',
            image: '/images/blog/blog3.jpg',
            description: 'From foam-tipped to brush-based, our applicators are engineered for precision and versatility. ',
        },
        {
            name: 'Applicators',
            link: '/blog/blog-one',
            image: '/images/blog/blog3.jpg',
            description: 'From foam-tipped to brush-based, our applicators are engineered for precision and versatility. ',
        },
        {
            name: 'Pouchettes',
            link: '/blog/blog-one',
            image: '/images/blog/blog2.jpg',
            description: 'Custom-designed for elegance and functionality, our pouchettes are crafted to hold and protect cosmetic essentials. ',
        },
        {
            name: 'Puff',
            link: '/blog/blog-one',
            image: '/images/blog/blog1.jpg',
            description: 'Our flocked foam powder puffs, a pioneering invention, cater to all market sectors.',
        },
    ];

    return (
        <>
            <section>
                <div className="container padding">
                    <div className="blogs-intro">
                        <h2>Blogs</h2>
                        <p>Embark on a captivating journey through the world of beauty and innovation with Caressa Kahn. As a trailblazing figure in the cosmetic industry, Caressa's blog invites you to explore the latest trends, expert tips, and the artistry behind her renowned makeup creations. Immerse yourself in the intersection of passion and expertise as Caressa shares her insights, making every read an enriching experience in the realm of glamour and self-expression</p>
                    </div>

                    <div className="blogs-sec-flex">
                        {blogs.map((blog) => (
                            <Link key={blog.name} to={blog.link} className="blogs-section">
                                <img src={process.env.PUBLIC_URL + blog.image} alt="Blog List - Caressa Kahn" />
                                <div className="blogs-section-text">
                                    <h3>{blog.name}</h3>
                                    <p>{blog.description}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogList;
