import React from 'react'
import './Planet.css'
import LeftSection from '../../../Components/LeftSection/LeftSection'
const Planet = () => {
    const leftsection1 = {
        heading: "Commitment to Our Planet",
        paragraphs: [
            {
                subtitle: 'Use of sustainable practices and materials',
                content: "Embracing eco-conscious production, Caressa Kahn utilizes vegan leather and biodegradable components, ensuring every product reflects a commitment to environmental stewardship and sustainable beauty.",
            },
            {
                subtitle: 'Efforts to reduce carbon footprint',
                content: "Across all business facets, from manufacturing to packaging, we actively implement strategies to lower our carbon footprint, demonstrating our dedication to a healthier planet.",
            },
            {
                subtitle: "Development of lightweight products",
                content: "By designing lightweight products, we aim to minimize shipping emissions, contributing to a significant reduction in the overall environmental impact of our global distribution.",
            },
        ],
        imageSrc: "/images/sustainability/planet.jpg",
        altText: "Commitment to Our Planet - Caressa Kahn"

    }

    return (
        <>
            <section className='planet' id='planet'>
                <div className="container padding">
                    <LeftSection {...leftsection1} />
                </div>
            </section>
        </>
    )
}

export default Planet
