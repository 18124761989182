import React from 'react'
import Home from './Pages/Home/Home'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Header } from './Components/Header/Header'
import About from './Pages/About/About'
import Career from './Pages/Career/Career'
import Footer from './Components/Footer/Footer'
import Contact from './Pages/Contact/Contact'
import Product from './Pages/Product/Product'
import Innovation from './Pages/Innovation/Innovation'
import Sustainability from './Pages/Sustainability/Sustainability'
import ScrollToTop from './Components/ScrollToTop'
import BlogPage from './Pages/BlogPage/BlogPage'
import BlogOne from './BlogPages/BlogOne/BlogOne'
// import Floater from './Components/Floater/Floater'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        {/* <Floater /> */}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
          <Route path="/innovations" element={<Innovation />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/contact" element={<Contact />} />

          {/* Blog Inner pages */}
          <Route path="/blog/blog-one" element={<BlogOne />} />
        </Routes>

        <Footer />
      </BrowserRouter>




    </>
  )
}

export default App
