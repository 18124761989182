import React from 'react'
import './aboutOverview.css'

const AboutOverview = () => {
    return (
        <>
            <section className="about-overview" id="about">
                <div className="container padding">
                    <div className="aboutOverview-flex">
                        <div className="aboutOverview-sec1">
                            <h2>About</h2>
                            <p>Caressa Kahn, with its roots deeply embedded in tradition and eyes set on the future, has been a beacon of innovation and craftsmanship since 1932. Our journey from a modest beginning to becoming a leader in the cosmetic accessory industry reflects our commitment to quality, sustainability, and inclusivity, driving beauty forwardfor every individual.</p>
                        </div>
                        <div className="aboutOverview-sec1">
                            <h2>Vision</h2>
                            <p>To become the World's top choice for supply, development, design, and manufacture of beautiful cosmetic application products.</p>
                        </div>
                        <div className="aboutOverview-sec1">
                            <h2>Mission</h2>
                            <p>Caressa Kahn adds colour and beauty to the World through the supply of high quality, innovative cosmetic applicators to the major beauty houses.  We strive to design products to exceed our customers’ desires and aspirations and enable individuals to achieve flawless make-up.</p>
                        </div>
                        {/* <div className="aboutOverview-sec1">
                        <h2>Statement from product</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacinia mauris felis, euismod maximus urna fermentum vitae. Nullam sagittis purus ut facilisis vehicula. Donec consequat magna mollis risus varius, non lacinia velit feugiat. Mauris euismod augue in gravida tempor. Curabitur cursus interdum quam ut ullamcorper. Cras id felis ex. Nunc rhoncus libero tellus, ac cursus mi ultrices id. Nulla facilisi.</p>
                        </div> */}

                        {/* <div className="aboutOverview-sec2">
                            <div className="aboutOverview-inner">
                                <h3>Vision</h3>
                                <p>To become the World's top choice for supply, development, design, and manufacture of beautiful cosmetic application products.</p>
                            </div>
                            <div className="aboutOverview-inner">
                                <h3>Mission</h3>
                                <p>Caressa Kahn adds colour and beauty to the World through the supply of high quality, innovative cosmetic applicators to the major beauty houses.  We strive to design products to exceed our customers’ desires and aspirations and enable individuals to achieve flawless make-up.</p>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="statement-text">
                        <h2>Statement from product</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacinia mauris felis, euismod maximus urna fermentum vitae. Nullam sagittis purus ut facilisis vehicula. Donec consequat magna mollis risus varius, non lacinia velit feugiat. Mauris euismod augue in gravida tempor. Curabitur cursus interdum quam ut ullamcorper. Cras id felis ex. Nunc rhoncus libero tellus, ac cursus mi ultrices id. Nulla facilisi.</p>
                    </div> */}
                </div>
            </section>



        </>
    )
}

export default AboutOverview