import React from 'react'
import './ManufacturingExcellence.css'
import LeftSection from '../../../Components/LeftSection/LeftSection'
import RightSection from '../../../Components/RightSection/RightSection'

const ManufacturingExcellence = () => {
    const leftsection1 = {
        heading: "Manufacturing Excellence",
        paragraphs: [
            {
                subtitle: 'Plants in the UK and China for global reach',
                content: "Caressa Kahn’s manufacturing facilities in the UK and China epitomize the brand's commitment to delivering high-quality cosmetic accessories worldwide. These strategically located plants enable efficient production and distribution, ensuring that clients across the globe receive products that meet the highest standards of craftsmanship and innovation.",
            },
        ],
        imageSrc: "/images/products/factory.jpg",
        altText: "Manufacturing Excellence - Caressa Kahn"
    };
    const rightSection1 = {
        heading: 'Employee Welfare:',
        paragraphs: [
            {
                subtitle: '',
                content:
                    "Caressa Kahn's adherence to <strong>SA8000 certification</strong> standards underscores its commitment to ethical working conditions and fair labour practices. This globally recognized certification ensures the company maintains a socially responsible workplace, prioritizing the well-being, rights, and dignity of its employees. By upholding these standards, Caressa Kahn not only fosters a positive work environment but also sets an example for ethical business practices in the cosmetic accessory industry.",
            },
        ],
        imageSrc: '/images/sustainability/employee-welfare.png',
        altText: 'Employee Welfare - Caressa Kahn',
    };
    const leftsection2 = {
        heading: "In-House Capabilities",
        paragraphs: [
            {
                subtitle: '3D design and prototyping for innovation',
                content: "Caressa Kahn's in-house 3D design and prototyping capabilities are pivotal for innovation, enabling rapid development and testing of new products. This process ensures that each product not only meets the aesthetic and functional demands of the market but also embodies the latest in cosmetic application technology.",
            },
            {
                subtitle: 'Comprehensive engineering for product quality and efficiency',
                content: "Through comprehensive engineering, Caressa Kahn ensures each product's quality and efficiency from conception to production. This meticulous approach to engineering integrates advanced techniques and materials, guaranteeing superior product performance and durability in the cosmetic industry.",
            },
        ],
        imageSrc: "/images/products/inhousecapability.jpg",
        altText: "In-House Capabilities - Caressa Kahn"
    };


    return (
        <>
            <section className='manufacturing-excellence' id='manufacturing-excellence'>
                <div className="container">
                    <LeftSection {...leftsection1} />
                    <RightSection {...rightSection1} />
                    <LeftSection {...leftsection2} />
                </div>
            </section>
        </>
    )
}

export default ManufacturingExcellence
