import React from 'react'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import IntroSection from '../../Components/IntroSection/IntroSection'
import Positions from './Positions/Positions'
import Passion from './Passion/Passion'
import Blog from '../../Components/Blog/Blog'
import { Helmet } from "react-helmet";

const Career = () => {


  const innerBanner = {
    innerBannerImgMobile: "/images/banners/mobile/career1.jpg",
    innerBannerImgMobileAlt: "Career Banner - Caressa Kahn",
    innerBannerImg: "/images/banners/career1.jpg",
    innerBannerImgAlt: "Career Banner - Caressa Kahn",
    innerBannerHead: "Shape the Future of Beauty with Caressa Kahn",
    innerBannerSubHead: "Discover fulfilling opportunities beyond the product, shaping the future of beauty with your unique talents and passion.",
    innerMenus: [
      { label: "Open Position", path: "#open-positions" },
      { label: "Apply Now", path: "#apply" },
    ]
  }

  const introSection = {
    introHead: "Career",
    introPara: "Embark on an impactful journey with Caressa Kahn across our UK and China facilities and international offices. We offer diverse roles for passionate individuals who thrive in an inclusive environment. Unleash your creativity, hone your expertise, and contribute to innovative solutions that define the industry. Join us and build a fulfilling career with purpose, making a difference with every stroke."
  }



  return (
    <>

      <Helmet>
        {/* Meta Tags */}
        <meta name="description" content="Explore exciting career opportunities at Caressa Kahn. Join our team and be part of a dynamic company." />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Career - Caressa Kahn" />
        <meta property="og:description" content="Explore exciting career opportunities at Caressa Kahn. Join our team and be part of a dynamic company." />
        <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />

        {/* Canonical Tag */}
        <link rel="canonical" href="" />
        <title>Career - Caressa Kahn</title>
      </Helmet>
      <InnerBanner {...innerBanner} />
      <IntroSection {...introSection} />
      <Positions />
      <Passion />
      <Blog />
    </>
  )
}

export default Career