import React from 'react'
import './Canvas.css'
import Button from '../../../Components/Button/Button'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import 'swiper/css/scrollbar';
// import { Scrollbar } from 'swiper/modules';
const Canvas = () => {
    return (
        <>
            <section className='canvas  padding'>
                {/* <div className="container"> */}
                    <div className="canvas-header">
                        <h2>PRODUCTS</h2>
                    </div>

                    <div className="canvas-container">

                        <Swiper
                            slidesPerView={2.5}
                            
                            // centeredSlides={true}
                            // scrollbar={true}
                            // modules={[Scrollbar]}
                            breakpoints={{
                                1: {
                                    slidesPerView: 1.4,
                                    spaceBetween: 50
                                },
                                456: {
                                    slidesPerView: 1.6,
                                    spaceBetween: 50
                                },
                                856: {
                                    slidesPerView: 2.8,
                                    spaceBetween: 95
                                }
                            }}
                            className='product-swiper'>
                            <SwiperSlide >
                                <div className="product-card">
                                    <div className="product-img">
                                        <img src={process.env.PUBLIC_URL + `/images/puff.jpg`} alt="Products - Caressa Kahn" />
                                    </div>
                                    <div className="product-content">
                                        <p>Signature Flocked Foam Puffs</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="product-card">
                                    <div className="product-img">
                                        <img src={process.env.PUBLIC_URL + `/images/brush.jpg`} alt="Products - Caressa Kahn" />
                                    </div>
                                    <div className="product-content">
                                        <p>Elegant Brushes</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="product-card">
                                    <div className="product-img">
                                        <img src={process.env.PUBLIC_URL + `/images/applicator.jpg`} alt="Products - Caressa Kahn" />
                                    </div>
                                    <div className="product-content">
                                        <p>Innovative Applicators</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="product-card">
                                    <div className="product-img">
                                        <img src={process.env.PUBLIC_URL + `/images/compact.jpg`} alt="Products - Caressa Kahn" />
                                    </div>
                                    <div className="product-content">
                                        <p>Cushion Compacts</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        
                        </Swiper>
                    </div>
                    <div className='product-explore'>
                        <Button link={'/product'} content={'Explore'} />
                        </div>
                {/* </div> */}
            </section>
        </>
    )
}

export default Canvas
