import React from 'react'
import './passion.css'

const Passion = () => {
    return (
        <>
            <section className="passion" id='passionate'>
                <div className="container">
                    <h2>Passionate about beauty and innovation?</h2>
                    <p>Your dream role might not be listed! Tell us what inspires you – from design to sustainability – and submit your resume. We believe in uncovering hidden talents and fostering unique contributions. Let's shape the future of beauty, together.</p>
                </div>

            </section>
        </>
    )
}

export default Passion