import React from 'react'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import InnovationSection from './InnovationSection/InnovationSection'
import Blog from '../../Components/Blog/Blog'
import { Helmet } from "react-helmet";


const Innovation = () => {
    const innerBanner = {
        innerBannerImgMobile: "/images/banners/mobile/innovation.jpg",
        innerBannerImgMobileAlt: "Innovation Banner - Caressa Kahn",
        innerBannerImg: "/images/banners/innovation.jpg",
        innerBannerImgAlt: "Innovation Banner - Caressa Kahn",
        innerBannerHead: "Innovating Beauty: The Caressa Kahn Way",
        innerBannerSubHead: "Where Science Meets Sustainability in Cosmetic Excellence",
        innerMenus: [
            { label: "Science of Beauty", path: "#science-of-beauty" },
            { label: "Sustainability", path: "#sustainability" },
            { label: "Customization", path: "#customization" },
            { label: "Multi-functionality", path: "#multi-functionality" },
            { label: "Diversity", path: "#diversity" },
            { label: "Inclusivity", path: "#inclusivity" }
        ]
    }





    return (
        <>

            <Helmet>
                {/* Meta Tags */}
                <meta name="description" content="Discover groundbreaking innovation at Caressa Kahn. " />

                {/* Open Graph Tags */}
                <meta property="og:title" content="Innovation - Caressa Kahn" />
                <meta property="og:description" content="Discover groundbreaking innovation at Caressa Kahn. " />
                <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />

                {/* Canonical Tag */}
                <link rel="canonical" href="" />
                <title>Innovation - Caressa Kahn</title>
            </Helmet>

            <InnerBanner {...innerBanner} />
            <InnovationSection />
            <Blog />


        </>
    )
}

export default Innovation