import React from 'react'
import './innovationSection.css'
import LeftSection from '../../../Components/LeftSection/LeftSection'
import RightSection from '../../../Components/RightSection/RightSection'

const InnovationSection = () => {
  const leftsection1 = {
    heading: "The Science of Beauty:",
    paragraphs: [
      {
        subtitle: 'R&D-driven innovations: ',
        content: "Our relentless R&D focuses on enhancing user experience and product     effectiveness, ensuring each innovation directly responds to the evolving     needs of the beauty industry and its diverse clientele.",
      },
      {
        subtitle: 'Advanced manufacturing processes:',
        content: "We employ cutting-edge manufacturing techniques to not only boost the functionality of our cosmetic accessories but also to elevate their design and appeal, merging utility with beauty.",
      },
      {
        subtitle: "Incorporation of 'Green Science':",
        content: "In our quest for eco-friendly beauty solutions, 'Green Science' plays a crucial role, guiding the development of products that are as gentle on     the environment as they are on your skin.",
      },
      {
        subtitle: 'Continuous exploration of new materials and technologies:',
        content: "Our commitment to excellence drives us to perpetually seek out and test new materials and technologies, aiming to refine application precision and extend the longevity of our products, setting new standards in the     cosmetic industry.",
      },
    ],
    imageSrc: "/images/Innovation/Science-of-Beauty.jpg",
    altText: "Science of Beauty - Caressa Kahn"

  }
  const rightSection1 = {
    heading: 'Sustainability:',
    paragraphs: [
      {
        subtitle: 'Eco-friendly products',
        content:
          "Caressa Kahn emphasizes eco-friendly products, with applicators made from recycled materials like vegan leather, bamboo, silicone, and ocean plastic. Our innovations include biodegradable options and reduced packaging waste, aligning with consumer demands for sustainable beauty solutions.",
      },
      {
        subtitle: 'Bamboo Brushes: ',
        content:
          "Crafted from sustainable bamboo, these brushes offer an eco-conscious alternative for makeup application, combining functionality with environmental responsibility.",
      },
      {
        subtitle: 'Silicone Sponges:',
        content:
          "Non-porous and easy to clean, silicone sponges provide a hygienic, reusable option, reducing waste and promoting sustainability.",
      },
      {
        subsubtitle: "Teardrop Silicone Sponge:",
        subcontent: "Designed for targeted application, its shape allows for precise blending in hard-to-reach areas, accommodating all skin tones.",
      },
      {
        subsubtitle: "Flat Silicone Sponge:",
        subcontent: "Its flat surface is ideal for evenly spreading foundation or concealer, catering to diverse skin color needs.",
      },
    ],
    imageSrc: '/images/Innovation/Silicone-Sponges.jpg',
    altText: 'Sustainability - Caressa Kahn',
  };
  const leftsection2 = {
    heading: "Customization:",
    paragraphs: [
      {
        subtitle: '',
        content: "Caressa Kahn is at the forefront of personalized beauty trends driving the demand for customizable applicators, catering to individual needs. Our innovations include applicators with varying shapes and sizes for different facial features and those that dispense products based on skin type, enhancing the user experience.",
      },
      {
        subtitle: 'Adjustable Foundation Brush:',
        content: "This brush's adjustable bristles allow for tailored application, suiting different skin textures and achieving desired coverage levels.",
      },
      {
        subtitle: "Skin-Type Specific Sponges:",
        content: "Sponges designed to cater to specific skin types, ensuring optimal product application and enhancing makeup performance.",
      },
    ],
    imageSrc: "/images/Innovation/Foundation-Brush.jpg",
    altText: "Customization - Caressa Kahn"
  }

  const rightSection2 = {
    heading: 'Multi-functionality:',
    paragraphs: [
      {
        subtitle: '',
        content:
          "Our applicators are evolving to offer multiple functions in one tool, such as brushes that can be used for foundation and concealer or sponges for wet and dry application. This versatility enhances convenience and streamlines the makeup routine.",
      },
      {
        subtitle: 'Dual-Ended Brush:',
        content:
          "One end for foundation, the other for concealer, this brush simplifies makeup application and saves space in beauty kits.",
      },
      {
        subtitle: 'All-in-One Sponge:',
        content:
          "Designed for both wet and dry use, this sponge adapts to different product textures, offering versatility in application.",
      },
    ],
    imageSrc: '/images/Innovation/Multi-functionality.jpg',
    altText: 'Multi-functionality - Caressa Kahn',
  };

  const leftsection3 = {
    heading: "Microfiber Applicators:",
    paragraphs: [
      {
        subtitle: '',
        content: "Our microfiber applicators, with ultra-fine fibers, ensure even product distribution without excess absorption. Customizable in density and texture, they cater to different skin types and preferences, providing a gentle application experience that enhances makeup performance across diverse skin tones.",
      },
      {
        subtitle: 'Dense Microfiber Puff:',
        content: "Ideal for full coverage, this puff's dense fibers deliver a smooth, even      application, suitable for all skin tones.",
      },
      {
        subtitle: "Soft Microfiber Brush:",
        content: "This brush's soft fibers offer a light, airbrushed finish, adaptable to various skin types and makeup styles.",
      },
    ],
    imageSrc: "/images/Innovation/Microfiber-Puff.jpg",
    altText: "Microfiber Applicators - Caressa Kahn"
  }

  const rightSection3 = {
    heading: 'Biodegradable and Compostable Applicators:',
    paragraphs: [
      {
        subtitle: '',
        content:
          "In response to environmental consciousness, there's a rising demand for sustainable applicators made from materials like bamboo, recycled plastic, or seaweed. Our eco-friendly options can be composted or biodegraded after use, promoting sustainability while catering to diverse beauty needs.",
      },
      {
        subtitle: 'Bamboo Foundation Brush:',
        content:
          "Eco-friendly and suitable for all skin tones, this brush combines sustainability with versatile makeup application.",
      },
      {
        subtitle: 'Seaweed-infused Sponge:',
        content:
          "Compostable and gentle on the skin, this sponge offers an environmentally conscious choice for diverse users.",
      },
    ],
    imageSrc: '/images/Innovation/bamboo-foundation.jpg',
    altText: 'Bamboo Foundation Brush - Caressa Kahn',
  };

  const leftsection4 = {
    heading: "Inclusivity:",
    paragraphs: [
      {
        subtitle: 'Applicators in a wider range of colors:',
        content: "Traditionally limited to shades like white or beige, brands, including Caressa Kahn, are now offering applicators in a broader spectrum of colors to match various skin tones, promoting inclusivity in beauty tools.",
      },
      {
        subtitle: 'Applicators for different facial features:',
        content: "Recognizing the diversity in facial features, there are now applicators designed for specific needs, such as those tailored for monolids or hooded eyes, ensuring that everyone's unique beauty is catered to.",
      },
      {
        subtitle: "Applicators for people with disabilities:",
        content: "To accommodate users with limited dexterity or mobility, some brands are creating more accessible applicators, making the beauty experience      inclusive for individuals with disabilities.",
      },
    ],
    imageSrc: "/images/Innovation/wider-range.jpg",
    altText: "Inclusivity - Caressa Kahn"
  }


  return (
    <>

      <section className="science-of-beauty" id="science-of-beauty">
        <div className="container padding">
          <LeftSection {...leftsection1} />
        </div>
      </section>


      <section className="sustainability" id="sustainability">
        <div className="container padding">
          <RightSection {...rightSection1} />
        </div>
      </section>


      <section className="customization" id="customization">
        <div className="container padding">
          <LeftSection {...leftsection2} />
        </div>
      </section>

      <section className="multi-functionality" id="multi-functionality">
        <div className="container padding">
          <RightSection {...rightSection2} />
        </div>
      </section>

      <section className="diversity" id="diversity">
        <div className="container padding">
          <h2 className="diversity-head">Diversity:</h2>
          <LeftSection {...leftsection3} />
          <RightSection {...rightSection3} />
        </div>
      </section>

      <section className="inclusivity" id="inclusivity">
        <div className="container padding">
          <LeftSection {...leftsection4} />


          <div className="inclusivity-para">
            <p>These innovations reflect Caressa Kahn's commitment to inclusivity, ensuring that their products cater to a wide range of skin tones, colours, and individual needs, promoting diversity and personalization in the cosmetic applicator industry.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default InnovationSection