import React from 'react'
import './Blog.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
const Blog = () => {
    return (
        <>
            <section className='blog-section'>

                <div className="container blog-flex">
                    <div className="blog-content">
                        <h2>Update</h2>
                    </div>
                    <div className="blog-slides">
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={30}
                            scrollbar={true}
                            modules={[Scrollbar]}
                            breakpoints={{
                                1: {
                                    slidesPerView: 1,
                                },
                                320: {
                                    slidesPerView: 1,
                                },
                                456: {
                                    slidesPerView: 2,
                                },
                                856: {
                                    slidesPerView: 3,
                                },
                                1280: {
                                    slidesPerView: 3,
                                },
                                1441: {
                                    slidesPerView: 4,
                                }
                            }}
                            className='blog-swiper'>
                            <SwiperSlide >
                                <div className="blog-slide-flex">
                                    <div className="blog-img-holder">
                                        <img src={process.env.PUBLIC_URL + `/images/blog1.jpg`} alt="Blog - Caressa Kahn" />
                                    </div>
                                    <div className="blog-text">
                                        <p>February 14, 2024</p>
                                        <p>The Evolution of Cosmetic Applicators: A Journey from Tradition to Innovation.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="blog-slide-flex">
                                    <div className="blog-img-holder">
                                        <img src={process.env.PUBLIC_URL + `/images/blog2.jpg`} alt="Blog - Caressa Kahn" />
                                    </div>
                                    <div className="blog-text">
                                        <p>February 01, 2024</p>
                                        <p>Sustainable Beauty: How Caressa Kahn Leads with Eco-Conscious Innovations.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="blog-slide-flex">
                                    <div className="blog-img-holder">
                                        <img src={process.env.PUBLIC_URL + `/images/blog3.jpg`} alt="Blog - Caressa Kahn" />
                                    </div>
                                    <div className="blog-text">
                                        <p>January 27, 2024</p>
                                        <p>Customization in Cosmetics: The Rise of Personalized Beauty Solutions.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="blog-slide-flex">
                                    <div className="blog-img-holder">
                                        <img src={process.env.PUBLIC_URL + `/images/blog4.jpg`} alt="Blog - Caressa Kahn" />
                                    </div>
                                    <div className="blog-text">
                                        <p>January 16, 2024</p>
                                        <p>Empowering Through Beauty: Caressa Kahn's CSR Initiatives.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="blog-slide-flex">
                                    <div className="blog-img-holder">
                                        <img src={process.env.PUBLIC_URL + `/images/blog1.jpg`} alt="Blog - Caressa Kahn" />
                                    </div>
                                    <div className="blog-text">
                                        <p>February 14, 2024</p>
                                        <p>The Evolution of Cosmetic Applicators: A Journey from Tradition to Innovation.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="blog-slide-flex">
                                    <div className="blog-img-holder">
                                        <img src={process.env.PUBLIC_URL + `/images/blog2.jpg`} alt="Blog - Caressa Kahn" />
                                    </div>
                                    <div className="blog-text">
                                        <p>February 01, 2024</p>
                                        <p>Sustainable Beauty: How Caressa Kahn Leads with Eco-Conscious Innovations.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Blog
