import React from 'react'
import './introsection.css'

const IntroSection = ({ introHead, introPara }) => {
    return (
        <>
            <section className="container introSection">
                <h1>{introHead}</h1>
                <p>{introPara}</p>
            </section>
        </>
    )
}

export default IntroSection