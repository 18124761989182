import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './innerbanner.css';

const InnerBanner = ({ innerBannerImgMobile, innerBannerImgMobileAlt, innerBannerImg, innerBannerImgAlt, innerBannerHead, innerBannerSubHead, innerMenus }) => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeMenu, setActiveMenu] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = (path, index) => (event) => {
    event.preventDefault(); // Prevent default link behavior
    setActiveMenu(index);

    const hash = path.split('#')[1];
    const targetElement = document.getElementById(hash);

    if (targetElement) {
      // Calculate the offset by subtracting the height of the header
      const header = document.querySelector('header');
      const headerHeight = header ? header.offsetHeight : 0;
      const offsetTop = targetElement.offsetTop;
      const offset = offsetTop - headerHeight - 130; // Adjust the offset as needed

      // Scroll to the target element
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });

      // Manually navigate after scrolling
      navigate(path);
    }
  };

  return (
    <>
      <section className="inner-banner">
        {isMobile ? (
          <img src={innerBannerImgMobile} alt={innerBannerImgMobileAlt} />
        ) : (
          <img src={innerBannerImg} alt={innerBannerImgAlt} />
        )}
        <div className="inner-banner--content">
          <h2>{innerBannerHead}</h2>
          <h3>{innerBannerSubHead}</h3>
        </div>
      </section>

      {innerMenus.length > 0 && (
        <div className="banner-tab-flex">
          <div className="banner-tab-flex-sec">
            {innerMenus.map((menu, index) => (
              <Link
                key={index}
                to={menu.path}
                onClick={handleClick(menu.path, index)}
                className={activeMenu === index ? 'active' : ''}
              >
                {menu.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default InnerBanner;
