import React from 'react'
import './safety.css'

const Safety = () => {
    return (
        <>
            <div className="safety-side" id="quality-safety">
                <div className="container padding">
                    <div className="safety-side-flex">
                        <div className="quality-flex">
                            <div className="quality-flex-text">
                                <h2>Quality & Safety Side</h2>
                                <p>Caressa Kahn upholds exemplary Quality & Safety Standards, evident in its SA8000 certification for the China plant, CKC Manufacturing (AH) Co. Ltd. This certification underscores the company's commitment to social accountability, ensuring ethical working conditions and fair labour practices. Furthermore, the dedication to comprehensive quality assurance aligns with standards, emphasizing consistent quality management and customer satisfaction across its operations. These certifications highlight Caressa Kahn's dedication to maintaining high ethical standards, safety, and quality in every aspect of its business, from product development to manufacturing processes.</p>
                            </div>
                            <div className="quality-flex-img">
                                <img src={process.env.PUBLIC_URL + "images/about/certificate1.jpg"} alt="Certificate1 - Caressa Kahn" />
                                <img src={process.env.PUBLIC_URL + "images/about/certificate2.jpg"} alt="Certificate2 - Caressa Kahn" />
                            </div>
                        </div>
                        <div className="machinery-flex">
                            {/* <div className="machinery-flex-text">
                                <h2>Machinery</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacinia mauris felis, euismod maximus urna fermentum vitae. Nullam sagittis purus ut facilisis vehicula. Donec consequat magna mollis risus varius, non lacinia velit feugiat. Mauris euismod augue in gravida tempor. Curabitur cursus interdum quam ut ullamcorper. Cras id felis ex. Nunc rhoncus libero tellus, ac cursus mi ultrices id. Nulla facilisi.</p>
                            </div>
                            <div className="machinery-flex-img">
                                <img src={process.env.PUBLIC_URL + "images/about/machine.jpg"} alt="Machine - Caressa Kahn" width="100%" />
                            </div> */}
                        </div>
                    </div>
                    {/* <div className="standards--text">
                        <h2>Worker standards labour laws</h2>
                        <p>Nunc in dolor nisi. Nunc ac congue eros. Vestibulum tincidunt molestie massa quis pulvinar. Mauris nec varius orci. Nunc rutrum lacus sed leo pellentesque, a sollicitudin odio pretium. Proin scelerisque arcu vel velit fermentum pellentesque. Curabitur et dui ac ligula luctus faucibus quis a urna. Duis at lacus pretium, scelerisque lorem et, aliquam dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi et ex gravida, consequat metus vel, mollis ex. Praesent at augue est. Phasellus tempor sem pulvinar, volutpat ante in, iaculis ex. Ut lorem dolor, molestie vitae malesuada id, interdum in mi.</p>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Safety