import React, { useState } from 'react';
import "./positions.css"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().matches(/^\d{10}$/, 'Mobile must be a 10-digit number').required('Mobile is required'),
    position: Yup.string().test('required', 'Select the position', (value) => value !== ''),
    file: Yup.mixed()
        .required('File is required')
        .test(
            "fileType",
            "Only PDF, DOC, and DOCX file types are allowed",
            (value) => value && ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(value.type)
        ),
    message: Yup.string().required('Message is required'),
});

const Positions = () => {
    const [showPreloader, setShowPreloader] = useState(false);

    const handleSubmit = async (values, { setSubmitting, setFieldValue }) => {
        try {
            setShowPreloader(true);

            const formData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
                // If the value is a File object (file field), append it directly
                if (value instanceof File) {
                    formData.append(key, value);
                } else {
                    formData.append(key, value);
                }
            });

            const response = await fetch('http://localhost:3001/api/career', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Form submission failed');
            }

            const data = await response.json();

            if (data === 'success') {
                setShowPreloader(false);
                document.getElementById('SuccessMessageCareer').innerHTML = "Form Submitted successfully!";
                document.getElementById('SuccessMessageCareer').style.color = "green";
                setTimeout(() => {
                    document.getElementById('SuccessMessageCareer').innerHTML = '';
                }, 3000);
                document.getElementById('career-form').reset();
                document.getElementById('position-select').value = '';
                document.getElementById('cvUpload').value = '';
                // setFieldValue("file", null); // Reset the file input
                // document.getElementById('file2').value = "";
                // setFieldValue("file", null); // Reset the file input using Formik's setFieldValue function
            } else {
                alert('Form submission failed. Please try again later.');
            }
        } catch (error) {
            setShowPreloader(false);
            document.getElementById('SuccessMessageCareer').innerHTML = 'An error occurred while submitting the form.';
            document.getElementById('SuccessMessageCareer').style.color = "red";
            console.error('Error:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const updateFileName = (input) => {
        const selectedFileName = document.getElementById('selectedFileName');
        if (input.files.length > 0) {
            selectedFileName.innerHTML = input.files[0].name;
        } else {
            selectedFileName.innerHTML = 'Upload your CV';
        }
    };

    const [selectedPosition, setSelectedPosition] = useState("");

    const handleButtonClick = (position, setFieldValue) => {
        setSelectedPosition(position);  // Update local state
        setFieldValue("position", position); // Update Formik field
    };



    return (
        <>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    position: '',
                    message: '',
                    file: null,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setFieldValue }) => handleSubmit(values, { setSubmitting, setFieldValue })}
            >

                {({ setFieldValue }) => (
                    <>
                        <section className="positions" id='open-positions'>
                            <div className="container padding">
                                <h2>Open Positions</h2>
                                <div className="position-flex">
                                    <div className="position-text">
                                        <h3>Product Innovation Specialist</h3>
                                        <p>Developing and testing new beauty accessories, incorporating user insights and industry trends.</p>
                                    </div>
                                    <div className="position-btn">
                                        <a href="#apply"> <button id="position1" onClick={() => handleButtonClick("Product Innovation Specialist", setFieldValue)} className="career-btn">Apply For This Job</button></a>
                                    </div>
                                </div>
                                <div className="position-flex">
                                    <div className="position-text">
                                        <h3>Global Supply Chain Manager</h3>
                                        <p>Overseeing efficient material sourcing, production planning, and logistics across continents.</p>
                                    </div>
                                    <div className="position-btn">
                                        <a href="#apply"><button id="position2" onClick={() => handleButtonClick("Global Supply Chain Manager", setFieldValue)} className="career-btn">Apply For This Job</button></a>
                                    </div>
                                </div>
                                <div className="position-flex">
                                    <div className="position-text">
                                        <h3>Sustainability & Compliance Officer</h3>
                                        <p>Championing eco-friendly practices and ensuring adherence to international safety regulations.</p>
                                    </div>
                                    <div className="position-btn">
                                        <a href="#apply"><button id="position3" onClick={() => handleButtonClick("Sustainability & Compliance Officer", setFieldValue)} className="career-btn">Apply For This Job</button></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div style={{ display: showPreloader ? 'flex' : 'none' }} className='preloader-holder'>
                            <img src={process.env.PUBLIC_URL + `/images/1490.gif`} alt="Loader" />
                        </div>
                        <section className="positions-form" id='apply'>
                            <div className="container padding">
                                <h2>Apply Now</h2>

                                <Form className="career-form" method="POST" id="career-form">
                                    <div className="career-form-flex">
                                        <div className="career-form-sec1">
                                            <Field type="text" name="name" id="" placeholder="Name" />
                                            <ErrorMessage name="name" component="p" className="ErrorMessage" />
                                            <Field type="tel" name="phone" id="" placeholder="Phone" />
                                            <ErrorMessage name="phone" component="p" className="ErrorMessage" />
                                            <Field type="text" name="message" id="" placeholder="Message" />
                                            <ErrorMessage name="message" component="p" className="ErrorMessage" />
                                        </div>
                                        <div className="career-form-sec2">
                                            <Field type="email" name="email" id="" placeholder="Mail" />
                                            <ErrorMessage name="email" component="p" className="ErrorMessage" />
                                            <Field as="select"
                                                name="position"
                                                id="position-select"
                                                value={selectedPosition}
                                                onChange={(e) => {
                                                    setSelectedPosition(e.target.value);
                                                    setFieldValue("position", e.target.value);
                                                }}
                                            >
                                                <option value="">Select Position</option>
                                                <option value="Product Innovation Specialist">Product Innovation Specialist</option>
                                                <option value="Global Supply Chain Manager">Global Supply Chain Manager</option>
                                                <option value="Sustainability & Compliance Officer">Sustainability & Compliance Officer</option>
                                            </Field>
                                            <ErrorMessage name="position" component="p" className="ErrorMessage" />

                                            <div className="file-input-container">
                                                <label htmlFor="cvUpload" className="custom-file-label">
                                                    <span id="selectedFileName">Upload your CV</span> <img src={process.env.PUBLIC_URL + "images/career/upload.svg"} alt="Upload - Caressa Kahn" />
                                                </label>
                                                <input
                                                    type="file"
                                                    id="cvUpload"
                                                    className="file-input"
                                                    onChange={(event) => {
                                                        setFieldValue("file", event.currentTarget.files[0]);
                                                        updateFileName(event.currentTarget);
                                                    }}
                                                />
                                                <ErrorMessage name="file" component="p" className="ErrorMessage" />
                                            </div>

                                            <div className='career-btn-holder'>
                                                <button type="submit" className="career-submit">Submit</button>
                                            </div>
                                            <div id='SuccessMessageCareer'>

                                            </div>
                                        </div>
                                    </div>
                                </Form>

                            </div>
                        </section>
                    </>
                )}
            </Formik>

        </>
    )
}

export default Positions