import React from 'react'
import InnerBanner from '../../Components/InnerBanner/InnerBanner'
import IntroSection from '../../Components/IntroSection/IntroSection'
import ProductsSec from './ProductsSec/ProductsSec'
import Manufacturing from './Manufacturing/Manufacturing'
import Blog from '../../Components/Blog/Blog'
import { Helmet } from "react-helmet";

const Product = () => {
    const innerBanner = {
        innerBannerImgMobile: "/images/banners/mobile/products1.jpg",
        innerBannerImgMobileAlt: "Products Banner - Caressa Kahn",
        innerBannerImg: "/images/banners/products1.jpg",
        innerBannerImgAlt: "Products Banner - Caressa Kahn",
        innerBannerHead: "Beauty, Redefined: Our Signature Collection",
        innerBannerSubHead: "Discover Innovation and Elegance in Every Creation",
        innerMenus: [
            { label: "Products", path: "#products" },
            { label: "Processes", path: "#processes" },
            { label: "Quality Practices", path: "#quality-practices" }
        ]
    }

    const introSection = {
        introHead: "Overview",
        introPara: "At Caressa Kahn, our Products showcase a legacy of beauty innovation, where meticulous craftsmanship meets cutting-edge technology. From pioneering flocked foam puffs to state-of-the-art cushion compacts, each product embodies our commitment to quality, inclusivity, and sustainability. Our dedicated R&D and modern manufacturing facilities, including a strategic plant in China, underline our relentless pursuit of excellence. Dive into our diverse range of cosmetic accessories, designed to elevate every aspect of the makeup experience for our partners and their customers."
    }



    return (
        <>
            <Helmet>
                {/* Meta Tags */}
                <meta name="description" content="Explore our range of exceptional products at Caressa Kahn. Find the perfect solution for your needs today." />

                {/* Open Graph Tags */}
                <meta property="og:title" content="Product - Caressa Kahn" />
                <meta property="og:description" content="Explore our range of exceptional products at Caressa Kahn. Find the perfect solution for your needs today." />
                <meta property="og:image" content={process.env.PUBLIC_URL + "/images/Logo.svg"} />
                <meta property="og:url" content="" />
                <meta property="og:type" content="website" />

                {/* Canonical Tag */}
                <link rel="canonical" href="" />
                <title>Product - Caressa Kahn</title>
            </Helmet>

            <InnerBanner {...innerBanner} />
            <IntroSection {...introSection} />
            <ProductsSec />
            <Manufacturing />
            <Blog />




        </>
    )
}

export default Product